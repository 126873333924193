import React,{useState, useEffect} from 'react'
import Axios from 'axios';
 import Loading from '../../Loading';
import FormProduct from './FormProduct';

function EditProduct({match}) {
    const [product, setProduct]= useState(null)
    useEffect(() => {
        Axios.get(`/product/get-one/${match.params.uuid}`)
                    .then((result)=>setProduct(result.data[0]))
                    .catch((error)=>console.log(error.message));
                }, [match.params.uuid])
                return  product ? <FormProduct preloadedValues={product} match={match.params.uuid}/> :  <Loading/> 
}

export default EditProduct

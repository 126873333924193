import React from 'react'

function Company() {
    return (
        <div className="container-fluid  mt-5">
         {/* mx-5 to center and remove fluid to make it like used to be */}
        <div className="row mx-md-5 mx-lg-5">
            <div className="col-md-3 col-lg-3">
                <hr className="produit"/>
                <h3  className="h4 font titre-acc">GEOSYSTEMS TUNISIE</h3>
                <p className="text-left gst-intro">
                Le Répresentant Exclusif de LEICA GEOSYSTEMS en Tunisie
                    </p>
                
            </div>
            
            <div className="col-md-3 col-lg-3">
                <hr className="produit"/>
            <h3  className="h4 font titre-acc">VENTE</h3>
                
                <p className="text-left gst-intro ">
                Produits Géomatiques<br/>
                Solutions de Construction </p>
                 
                  
            </div>
            <div className="col-md-3 col-lg-3">
                <hr className="produit"/>
            <h3 className="h4 font titre-acc">Entretien & Réparation</h3>
                <p className="text-left gst-intro"> L'unique S.A.V En Tunisie</p> 
                
            </div>
            <div className="col-md-3 col-lg-3">
            <hr className="produit"/>
            <h3 className="h4 font titre-acc">Formation</h3>
                <p className="text-left gst-intro"> Formation & Démonstration</p>
                <hr className="separte "/>
            </div>
        </div>
        </div>
    )
}

export default Company

import React,{useState,useEffect} from 'react'
import Axios from "axios";
import Message from '../Message';
import Progress from '../Progress';
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
const schema = yup.object().shape({
  pictures: yup
  .mixed()
  .required("ce champ est obligatoire")
  .test("type", "ce champ doit être image de type jpeg", (value) => {
    return (value && value[0].type === "image/jpeg") || (value && value[0].type === "image/png");;
  }),
     
    product:yup
  .string()
  .required("ce champ est obligatoire"),
  
});

function AddPicture() {
    const history = useHistory();

    const[products, setProducts]=useState([]);
  useEffect(() => {
    Axios.get("/product/get-all")
          .then(response =>setProducts(response.data))
          .catch(error =>console.log(error.message))
  }, [])
    const [produitId, setProduitId] = useState(0)
 const [selectedImages, setSelectedImages ] = useState([]);
 const [selectedImagesName, setSelectedImagesName ] = useState("images");
 const [message, setMessage] = useState('');
 const [uploadPercentage, setUploadPercentage] = useState(0);
 const imagesHandler=e=>{
       setSelectedImages(e.target.files)
       setSelectedImagesName(e.target.files.name)
     }
     const { register , handleSubmit, errors } = useForm({
      resolver: yupResolver(schema)
    })
     const store= async ()=>{
         
        const newImages =  new FormData();
          newImages.append("produitId", produitId)
        if (selectedImages.length !== 1) {
            for (let i = 0; i < selectedImages.length; i++) {
                newImages.append("images", selectedImages[i])
               }
        } else {
            newImages.append("images",selectedImages[0])
        }
       
        try {
           await Axios.post("/picture/add-picture",newImages, {
            headers: {
              'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: progressEvent => {
              setUploadPercentage(
                parseInt(
                  Math.round((progressEvent.loaded * 100) / progressEvent.total)
                )
              );
    
              // Clear form
              setTimeout(() => setUploadPercentage(0), 2000);
              setTimeout(() => setMessage(''), 1000);
              setTimeout(() => setSelectedImages([]),1000);
              setTimeout(() => setSelectedImagesName('images'),1000)
              setTimeout(() => history.push("/gest-images"),2050)
               
            }
          });
    
          setMessage('Images Uploaded');
        } catch (error) {
          
        }
         
    }
    return (
        <div className="container">
            <div className="row">
             
                <h1>Ajouter Images Produit </h1><br/>
                {message ? <Message msg={message} /> : null}
            </div>
            <form onSubmit={handleSubmit(store)}  encType="multipart/form-data"  method="post">
                 <div className="form-group">
               <label for="category">Produit</label>
               <select id="category" className={`${errors.product? `form-control is-invalid`: `form-control` }`} value={produitId} onChange={(e)=>setProduitId(e.target.value)} name="product" ref={register} >
               <option disabled>Choisissez Produit</option> 
                     {
                        products.map(produit=>(<option key={produit.uuid} value={produit.uuid}>{produit.productName}</option>))
                     }    
                 </select>
                 <div className="error ">{errors.product?.message}</div>
            </div>
            <div className='custom-file   mb-3'>
               <input
                 type='file'
                 
                 className={`${errors.pictures? `custom-file-input is-invalid`: `custom-file-input` }`}
                 id='customFile'
                 multiple
                 accept="image/png,image/jpeg"
                 onChange={imagesHandler}
                 name="pictures"
                 ref={register}
                 />
      <label className='custom-file-label' htmlFor='customFile'>
        {selectedImagesName}
      </label>
      <div className="error ">{errors.pictures?.message}</div>
     
    </div> 
    <Progress percentage={uploadPercentage} />
    <button type="submit" className="btn mt-3" style={{backgroundColor:"#FF2B3A",color:'#fff'}}  >Ajouter</button>
            </form>
            </div>
            
             
    )
}

export default AddPicture

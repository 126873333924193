import './App.css'; 
import {BrowserRouter as Router, Switch, Route}  from 'react-router-dom';
import HomePage from './components/HomePage';
import Footer from './components/Footer';
import ProduitPage from './components/ProduitPage';
import EntreprisePage from "./components/EntreprisePage";
import ServicePage from "./components/ServicePage";
import ContactPage from "./components/ContactPage";
import PageStationTotale from './components/PageStationTotale';
import StManuelle from './components/StManuelle';
import ProduitDisc from './components/ProduitDisc';
import { useAuth0 } from "@auth0/auth0-react";
import LoginButton from './components/auth/LoginButton';
import NavigationAuth from './components/auth/NavigationAuth';
import Loading from './components/Loading';
import GestCat from './components/auth/category/GestCat';
import AddCategory from "./components/auth/category/AddCategory";
import EditCat from './components/auth/category/EditCat';
import GestSubcat from './components/auth/subcategory/GestSubcat';
import AddSubcategory from './components/auth/subcategory/AddSubcategory';
import EditSubCat from './components/auth/subcategory/EditSubCat';
import GestProduct from './components/auth/product/GestProduct';
import AddProduct from './components/auth/product/AddProduct';
import EditProduct from './components/auth/product/EditProduct';
import AddPicture from './components/auth/picture/AddPicture';
import GestPicture from './components/auth/picture/GestPicture';
import NoMatch from './components/NoMatch';
import GestCarousel from './components/auth/carousel/GestCarousel';
import AddCarousel from './components/auth/carousel/AddCarousel';
import EditCarousel from './components/auth/carousel/EditCarousel';
import GestBanner from './components/auth/Banner/GestBanner';
import EditBanner from './components/auth/Banner/EditBanner';
import AddBanner from './components/auth/Banner/AddBanner';
import GestHomePage from './components/auth/Home/GestHomePage';
import AddBlock from './components/auth/Home/AddBlock';
import EditBlock from './components/auth/Home/EditBlock';
import SearchResult from './components/SearchResult';
import GestClient from './components/auth/clients/GestClient';
import AddClient from './components/auth/clients/AddClient';
import GestTeam from './components/auth/Team/GestTeam';
import EditTeam from './components/auth/Team/EditTeam';
import ScrollToTop from './components/ScrollToTop';
import Navbar from './components/Navbar/Navbar';

function App() {
  const {isAuthenticated, isLoading } = useAuth0();
  return (
    
      <Router>
        {isLoading ? <Loading/> : isAuthenticated ? (
        <>
        <NavigationAuth/>
        <Switch>
           <Route component={GestHomePage} exact path="/gest-home"/>
           <Route component={AddBlock} path="/gest-home/add-block" />
           <Route component={EditBlock} path="/gest-home/edit-block/:uuid"/>
           <Route component={GestCat} exact path="/gest-cat" />
           <Route component={AddCategory} path="/gest-cat/add-cat"/>
           <Route component={EditCat} path="/gest-cat/edit-category/:uuid" />
           <Route component={GestSubcat}exact path="/gest-Sub-Category"/>
           <Route component={AddSubcategory} path="/gest-Sub-Category/add-sub-category"/>
           <Route component={EditSubCat} path="/gest-Sub-Category/edit-sub-category/:uuid"/>
           <Route component={GestProduct} exact path="/gest-Produit" />
           <Route component={AddProduct} path="/gest-Produit/add-product"/>
           <Route component={EditProduct} path="/gest-Produit/edit-product/:uuid" />
           <Route component={GestPicture} exact path="/gest-images"/>
           <Route component={AddPicture} path="/gest-images/add-picture" />
           <Route component={GestCarousel} exact path="/gest-caroussel"/>
           <Route component={AddCarousel} path="/gest-caroussel/add-carousel"/>
           <Route component={EditCarousel} path="/gest-caroussel/edit-carousel/:uuid"/>
           <Route component={GestBanner} exact path="/gest-banner" />
           <Route component={AddBanner} path="/gest-banner/add-banner"/>
           <Route component={EditBanner} path="/gest-banner/edit-banner/:uuid" />
           <Route component={GestClient} path="/gest-ref" exact />
           <Route component={AddClient} path="/gest-ref/add-ref"/>
           <Route component={GestTeam} path="/gest-equipe" exact/>
           <Route component={EditTeam} path="/gest-equipe/edit-equipe/:uuid"/>

        </Switch>
        
        </>
        ):(
          <div className="App">
        <ScrollToTop/> 
          <Navbar/>
            <Switch>
              <Route component={HomePage} exact path="/" />
              <Route component={EntreprisePage} path="/entreprise"/>
              <Route component={ProduitPage} exact path="/produit"/>
              <Route component={ServicePage} path="/service"/>
              <Route component={ContactPage} path="/contact"/>
              <Route component={SearchResult} path="/search/:word"/>
              <Route component={PageStationTotale}  path="/:catName/:uuid"    exact/>
              <Route component={StManuelle} path="/:match/:subcatName/:uuid" exact/>
              <Route component={ProduitDisc} path="/:matchcatName/:matchsubcatName/:prodName/:uuid" exact />
              <Route component={LoginButton} path="/test/test/jc7us36cz5h2ysbh7wsjy9pxawkh8m9n/singin/admin"  />
              <Route path="*">
                <NoMatch/>
               </Route>
               </Switch>  
            <Footer id="toppage"/> 
              </div>
        )}
        </Router>
  );
}

export default App;

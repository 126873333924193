import React,{useState,useEffect} from 'react';
import Carousel from "react-multi-carousel";
import Axios from "axios";
import {HashLink as Link} from "react-router-hash-link"
import "react-multi-carousel/lib/styles.css";
function ProductCarousel() {
    const [Product,setProduct]=useState([]);
    useEffect(() => {
        Axios.get("/frontend/get-homeProduct")
             .then(response =>setProduct(response.data))
             .catch(error => console.log(error.message))
      }, [])
    return (
        <div className="container-fluid   ">
          <div className="mx-md-5 mx-lg-5 mt-0 mt-md-3 mt-lg-0"> 
        <div className="heading">
                <h3 className="head font"><span className="before-title mr-3"></span>Vitrine</h3>
                
                  
              </div>
    <Carousel
    additionalTransfrom={1}
    arrows={true} 
    centerMode={false}
     
    draggable
    focusOnSelect={false}
    autoPlay
    autoPlaySpeed={3000}
  infinite   
    keyBoardControl
    minimumTouchDrag={80}
    renderButtonGroupOutside={false}
    renderDotsOutside={false}
    responsive={{
      desktop: {
        breakpoint: {
          max: 3000,
          min: 1024
        },
        items: 4,
         
      },
      mobile: {
        breakpoint: {
          max: 464,
          min: 180
        },
        items: 1,
        
      },
      tablet: {
        breakpoint: {
          max: 1024,
          min: 464
        },
        items: 3,
         
      }
    }}
    showDots={false}
    sliderClass="my-3" 
    swipeable
  >
        {Product.map((item,key)=>(
          <div className="owl-item" key={key}>
           <div className="bbb_viewed_item   d-flex flex-column align-items-center justify-content-center text-center">
               <div className="bbb_viewed_image">
                <Link to={`${item.Subcategory.Category.catName}/${item.Subcategory.subcatName}/${item.productName}/${item.uuid}`}>
                   <img src={`../../imgs/${item.productimgPath}`} alt="produits" />
                   </Link>
                   </div>
               <div className="bbb_viewed_content text-center">
                    <div className="bbb_viewed_name gst-intro">
                      <Link to={`${item.Subcategory.Category.catName}/${item.Subcategory.subcatName}/${item.productName}/${item.uuid}`} style={{color:"inherit",textDecoration:"none"}}>
                        {item.productName}  
                        </Link>
                        </div>
               </div>
                
           </div>
        </div>        ))}
         
      </Carousel>
       
      </div>
      <div className="row">
        <div className="col-md-12 col-lg-12 col-12">
          <div className="text-center">
            <Link to="/contact" style={{color:"inherit",textDecoration:"none"}}>
               
                <button className="button">
                Demandez un Devis
                 </button>
                </Link>
          </div>
        </div>
      </div>

      </div>
    )
}

export default ProductCarousel

import React,{useState,useEffect} from 'react';
import Axios from 'axios';
import Message from '../Message';
import Progress from "../Progress";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

const schema = yup.object().shape({
    imgPath: yup
    .mixed()
    .required("ce champ est obligatoire")
    .test("type", "ce champ doit être image de type jpeg", (value) => {
      return (value && value[0].type === "image/jpeg") || (value && value[0].type === "image/png");;
    }),
      
    link:yup
    .string()
    .required("ce champ est obligatoire"),
    
  });

function AddCarousel() {
    const history = useHistory();
     const [message, setMessage] = useState('');
     const [uploadPercentage, setUploadPercentage] = useState(0);
    
     const { register , handleSubmit, errors } = useForm({
        resolver: yupResolver(schema)
      }) 
    
      const onSubmit = async (data,e)=>{
        e.preventDefault();
        const newCarousel = new FormData()
        newCarousel.append('imgPath', data.imgPath[0])
        newCarousel.append('link',data.link)
        newCarousel.append("title",data.title)
        newCarousel.append("subTitle",data.subTitle)
        
        try {
          await Axios.post('/carousel/add-carousel', newCarousel, {
           headers: {
           'Content-Type': 'multipart/form-data'
           },
           onUploadProgress: progressEvent => {
          setUploadPercentage(
          parseInt(
           Math.round((progressEvent.loaded * 100) / progressEvent.total)
          )
          );
          //Clear form
          setTimeout(() => setUploadPercentage(0), 1000);
          setTimeout(() => setMessage(''), 1000);
          setTimeout(() => history.push("/gest-caroussel"),1100)
          }
          }).then(res=>{
           console.log(res)
          }).catch(err=>{
          console.log(err.message)
           });
          setMessage('File Uploaded');
          } catch (err) {
          if (err.response.status === 500) {
           setMessage('There was a problem with the server');
           } else {
          setMessage(err.response.data.msg);
          }
          }
     
    }
    const [pages, setPages] = useState([]);
    useEffect(() => {
       Axios.get("/home/get-all-categories")
       .then(res=>{
        setPages(res.data)
        })
    .catch(error=>{
         console.log(error.message)
    })
    }, [])
    return (
        <div className="container mt-5">
              
                 <div className="col-md-8 col-lg-8">
                 <h1>Ajouter Image Carousel </h1><br/>
                 {message ? <Message msg={message} /> : null}
                 <form onSubmit={handleSubmit(onSubmit)}  encType="multipart/form-data"  method="post">
                 <div className='form-group mb-4 mt3'>
                    <label>Lien</label>
                    <select className={`${errors.link? `form-control is-invalid`: `form-control` }`} id="name" name="link" aria-describedby="emailHelp" ref={register}>
              <optgroup label="les pages Statiques">
              <option value="/entreprise">Page Entreprise</option>
                    <option value="/produit">Page Produits</option>
                    <option value="/service">Page Services</option>
                    <option value="/contact">Page Contactez-nous</option>
              </optgroup>
              <optgroup label="les pages de Catégories">
                {
                  pages.map(item=>(
                    <option key={item.uuid} value={`/${item.catName}/${item.uuid}`}>page {item.catName}</option>
                      ))
                }
              </optgroup>
              <optgroup label="les pages de Sous Catégories">
              {
                  pages.map(item=>(<>
                   {item.Subcategories.map(val=>(<option key={val.uuid} value={`/${item.catName}/${val.subcatName}/${val.uuid}`}  >page {item.catName} {val.subcatName}</option>))}</>
                      ))
                }
              </optgroup> 
              <optgroup label="les pages de Produit">
              {
                  pages.map(item=>(<>
                   {item.Subcategories.map(value=>(<>
                  {value.Products.map(data=>(<option key={data.uuid} value={`/${item.catName}/${value.subcatName}/${data.productName}/${data.uuid}`}  >page {data.productName} </option> ))}
                   </>))}</>
                      ))
                }
              </optgroup>
              </select>
                    <div className="error ">{errors.link?.message}</div>
                    </div>
                    <div className='form-group mb-4 mt3'>
             <label>Titre</label>
             <input type="text" className={`${errors.title? `form-control is-invalid`: `form-control` }`}  name="title" ref={register}/>
             <div className="error ">{errors.title?.message}</div>
             </div>
             <div className='form-group mb-4 mt3'>
             <label>Sous Titre</label>
             <input type="text" className={`${errors.subTitle? `form-control is-invalid`: `form-control` }`}  name="subTitle" ref={register}/>
             <div className="error ">{errors.subTitle?.message}</div>
             </div>
                    <div className='form-group mb-4 mt3'>
                    <label>Image</label>      
           <input
            type='file'
            className={`${errors.imgPath? `form-control is-invalid`: `form-control` }`}
            id='customFile' 
             name="imgPath" 
             ref={register}

          />
          <div className="error ">{errors.imgPath?.message}</div>
          </div>
          <Progress percentage={uploadPercentage} />     
         <br/>
         <button type="submit" className="btn" style={{backgroundColor:"#FF2B3A",color:'#fff'}}  >Ajouter</button>
                </form>   
                 </div>
        </div>
    )
}

export default AddCarousel

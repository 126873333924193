import React,{useState, useEffect} from 'react'
import Axios from 'axios';
import Loading from '../../Loading';
import {FormBlock} from "./FormBlock";

function EditBlock({match}) {
    const [block,setBlock]=useState(null);
    useEffect(() => {
        Axios.get(`/home/get-one/${match.params.uuid}`)
                    .then((result)=>setBlock(result.data[0]))
                    .catch((error)=>console.log(error.message));
                }, [match.params.uuid])
                return  block ? <FormBlock preloadedValues={block} match={match.params.uuid}/> :  <Loading/> 
}

export default EditBlock

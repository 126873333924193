import React, { useCallback,useState,useEffect } from 'react';
import styled from 'styled-components';
import RightNav from './RightNav';

const StyledBurger = styled.div`
  width: 2rem;
  height: 2rem;
  position: fixed;
  top: 50px;
  right: 20px; 
  display: none;
  @media (max-width: 768px) {
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
  }
 
  div {
    width: 2rem;
    height: 0.25rem;
    background-color:  #FF2B3A;
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;
    &:nth-child(1) {
      transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
    }
    &:nth-child(2) {
      transform: ${({ open }) => open ? 'translateX(100%)' : 'translateX(0)'};
      opacity: ${({ open }) => open ? 0 : 1};
    }
    &:nth-child(3) {
      transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
    }
  }

`;

const Burger = ({ parentCallback ,navRef}) => {
  const [open, setOpen] = useState(false)
  const callback = useCallback((open) => {
    setOpen(open); 
    blurrBody();  
     
  }, []);
  
  function blurrBody(){
    if(!navRef.current.classList.contains("wrapper-navbar")  ){
     document.body.classList.add("blur-body") 
    }else{
      navRef.current.classList.remove("wrapper-navbar")
     document.body.classList.remove("blur-body")
       
    }
  }
   
  return (
    <>
      <StyledBurger open={open} id="burger" onClick={() =>{ setOpen(!open);parentCallback(!open);}}>
        <div />
        <div />
        <div />
      </StyledBurger>
      <RightNav open={open} callBack={callback} navRef={navRef}/>
    </>
  )
}

export default Burger
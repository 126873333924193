import React,{useState, useEffect} from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Axios from 'axios';
import { useHistory } from "react-router-dom";
const schema = yup.object().shape({
     link:yup
    .string()
    .required("ce champ est obligatoire"),
    
  });
  
export default function FormCarousel({preloadedValues, match}) {
    const history = useHistory();
    const { register, handleSubmit, errors } = useForm({
        defaultValues: preloadedValues,
        resolver: yupResolver(schema)
      });
      const onSubmit = async (data,e) => {
        e.preventDefault();
        const updatedCat = new FormData()
        updatedCat.append('imgPath', data.carousel[0])
        updatedCat.append("link",data.link)
        updatedCat.append("title",data.title)
        updatedCat.append("subTitle",data.subTitle)
         
         await Axios.put(`/carousel/edit/${match}`,updatedCat)
        .then((res)=>{
                console.log(res.data);
                history.push("/gest-caroussel");
            })
        .catch(err=>console.log(err.message))
       
        };

      const [links, setLinks]=useState([])
      useEffect(() => {
        Axios.get("/product/get-all")
             .then((res)=>setLinks(res.data))
             .catch((err)=>console.log(err.message))
    }, [])
   const [lien, setLien]=useState({
         link:"",
     })
     useEffect(() => {
         Axios.get(`/carousel/get-one/${match}`)
         .then(res=>setLien(res.data[0]))
         .catch((err)=>console.log(err))
     }, [])
    const onChange = (e) => {
        setLien({...lien, [e.target.name]: e.target.value});
      }
    return (
        <div className="container mt-5">
              
        <div className="col-md-8 col-lg-8">
        <h1>Modifier Image Carousel </h1><br/>
         
        <form onSubmit={handleSubmit(onSubmit)}  encType="multipart/form-data"  method="post">
        <div className='form-group mb-4 mt3'>
           <label>Lien</label>
           <select className={`${errors.link? `form-control is-invalid`: `form-control` }`}  name="link" value={lien.link} onChange={onChange} ref={register}   >
           <option value="/entreprise">Page Entreprise</option>
           <option value="/produit">Page Produits</option>
           <option value="/service">Page Services</option>
           <option value="/contact">Page Contactez-nous</option>
                {
               links.map(link=>(
               <option key={link.uuid} value={`/${link.Subcategory.Category.catName}/${link.Subcategory.subcatName}/${link.productName}/${link.uuid}`}>Produit Page {link.productName}</option>
               ))}
               
           </select>
           <div className="error ">{errors.link?.message}</div>
           </div>
           <div className='form-group mb-4 mt3'>
             <label>Titre</label>
             <input type="text" className={`${errors.title? `form-control is-invalid`: `form-control` }`}  name="title" ref={register}/>
             <div className="error ">{errors.title?.message}</div>
             </div>
             <div className='form-group mb-4 mt3'>
             <label>Sous Titre</label>
             <input type="text" className={`${errors.subTitle? `form-control is-invalid`: `form-control` }`}  name="subTitle" ref={register}/>
             <div className="error ">{errors.subTitle?.message}</div>
             </div>
           <div className='custom-file my-4 col-md-6 col-lg-6'>
               <label>Image</label>
           <input
            type='file'
            className={`${errors.carousel? `form-control is-invalid`: `form-control` }`}
            id='customFile'
            name="carousel"
            ref={register} 
            />
            <div className="error ">{errors.carousel?.message}</div>
             </div>
        <div className='custom-file  mt-3 mb-1 col-md-6 col-lg-6  text-right' >
           <div>
               <img src={`../../imgs/${preloadedValues.imgPath}`} style={{width:'40%',height:"40%"}} alt=""  name="bannerimgPath" ref={register}/>
           </div>
         
         
        </div>
        <div className="form-group"> 
        <button type="submit" className="btn mt-3" style={{backgroundColor:"#FF2B3A",color:'#fff'}}  >Ajouter</button>
         </div>
        
           </form>
           </div>
           </div>
           
    )
}

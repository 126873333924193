import React,{useReducer,useEffect,useState} from 'react';
import { useHistory } from "react-router-dom";
import Axios from 'axios';
import Loading from './Loading';
import { HashLink as Link } from 'react-router-hash-link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";
const initialState={
    loading:true,
    error:'',
    Products:[]
}
const reducer=(state,action)=>{
  switch(action.type){
      case "fetch success":
          return {
              loading:false,
              Products: action.payload,
              error:''
          }
     case "fetch error":
         return {
             loading:false,
             Products: [],
             error: "something went wrong "
         }
      default :
        return state
  }
}

function StManuelle({match}) {
    const [state,dispatch]= useReducer(reducer , initialState)
    const [banner,setBanner]=useState("")
    const [uuid, setUuid]=useState("")
    let history = useHistory();
    const goToPreviousPath = () => {
        history.goBack()
    }
     
    useEffect(()=>{
        Axios.get(`/frontend/get-all-subcat/${match.params.uuid}`)
             .then(res=>{
                 dispatch({type:"fetch success", payload:res.data});
                 setBanner(res.data[0].Subcategory.subcatbannerimgPath)
                 setUuid(res.data[0].Subcategory.Category.uuid)
                 })
             .catch(error=>{
                  dispatch({type:"fetch error"})
             })
    },[])
    return (
     
          <>
           <section id="toppage">
	 <nav>
		<ol className="cd-breadcrumb custom-separator">
			<li><Link className="breadcrumb-link" to="/">Accueil</Link></li>
            <li><Link className="breadcrumb-link" to="/produit">Produits</Link></li>
            <li><Link className="breadcrumb-link" to={`/${match.params.match}/${uuid}`}>{match.params.match}</Link></li>
              <li className="current"><em>{match.params.subcatName}</em></li>
			
		</ol>
	</nav>
</section>
         <div className="large-header" id="toppage">
      <img src={`../../imgs/${banner}`} id="img-banner" alt="gst-produit" style={{width:"100%"}}/> 
        </div>
        <div className="container-fluid">
<div className="row  mx-md-5 mx-lg-5 mt-5">
 {state.loading ? <Loading/> : <>{state.Products.map((item,key)=>(
 <div className="col-md-4 col-lg-4 mt-5 mb-5 products" key={key}> 
 <Link to={`/${match.params.match}/${item.Subcategory.subcatName}/${item.productName}/${item.uuid}`}  style={{color:"#000000"}}> 
            <img src={`../../imgs/${item.productimgPath}`} alt="produits" className="products-img"/>
</Link>
<hr className="produit"/>             
          <Link to={`/${match.params.match}/${item.Subcategory.subcatName}/${item.productName}/${item.uuid}`}  style={{color:"#000000"}}> 
          <h3 className="font">{item.productName}</h3></Link>
           <p className="text-left gst-intro" > {item.productIntro}  </p>  
          <Link to={`/${match.params.match}/${item.Subcategory.subcatName}/${item.productName}/${item.uuid}`} style={{color:"#000000"}}> En Savoir Plus <FontAwesomeIcon icon={faArrowRight} style={{color:"#FF2B3A"}} size="1x"/> </Link>
          <hr className="separte"/>
 </div>
 ))}</>}
</div>
</div>
    
        </>
    )
}

export default StManuelle

import React ,{useState,useEffect} from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faTrashAlt,faEdit} from "@fortawesome/free-solid-svg-icons";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Axios from "axios"


function GestProduct() {
  const [modal, setModal] = useState(false);
  const [uuid, setUuid]= useState("")

  const toggle = (uuid) => {
   setModal(!modal);
   setUuid(uuid);  }
   const closeModal=()=>{
     setModal(!modal);
   }
  const[products, setProducts]=useState([]);
  useEffect(() => {
    Axios.get("/product/get-all")
          .then(response =>setProducts(response.data))
          .catch(error =>console.log(error.message))
  }, [])
  const deleteProduct=async (uuid,e)=>{
     await  Axios.delete(`/product/delete/${uuid}`);
     setProducts(prevsubProduct=>prevsubProduct.filter(val=> val.uuid !== uuid));
     setModal(false);
    }
    return (
        <div className="container mt-5">
             <div className="col-lg-12 col-md-12 d-flex justify-content-between" style={{backgroundColor:'#c9c9c9'}}>
              <h1 className="justify-content-start" style={{color:'#000'}}>Ajouter Produit</h1>
               <Link to="/gest-Produit/add-product" style={{ marginTop:"1%"}}><button className="btn   justify-content-end" style={{backgroundColor:"#FF2B3A",color:"#fff"}}>Ajouter </button></Link>
              </div>
              <div className="col-lg-12 col-md-12 ">
              <table className="table table-hover">
  <thead>
    <tr>
      <th scope="col">Nom de Produit</th>
      <th>Catégorie</th>
      <th>Sous Catégorie</th>
      <th scope="col">image</th>
      <th scope="col">Actions</th>
       
    </tr>
  </thead>
  <tbody>
  {
   products.map(item=>(<tr key={item.uuid} >
  <th scope="row"  style={{width:"15%"}}>
  {item.productName}
 </th>
 <th scope="row"  style={{width:"15%"}}>{item.Subcategory.Category.catName}</th>
   <th scope="row" style={{width:"15%"}}>{item.Subcategory.subcatName}</th>
  <td style={{width:"30%"}}><img src={`../../imgs/${item.productimgPath}`} style={{width:"55px", height:"50px"}} alt=""/></td>
  <td><button className="btn btn-danger" onClick={(e)=>toggle(item.uuid)} >Supprimer <FontAwesomeIcon icon={faTrashAlt} style={{color:"#FFFFFF"}}/> </button> 
  <Link to={`/gest-Produit/edit-product/${item.uuid}`}  style={{color:"inherit"}}>  <button className="btn btn-info" >Modifier <FontAwesomeIcon icon={faEdit} style={{color:"#fff"}}/> </button></Link> </td> 
   </tr>
   ))}
 
 <Modal isOpen={modal} uuid={uuid} toggle={(e)=>closeModal}>
        <ModalHeader toggle={closeModal}>Vous êtes sûr ? </ModalHeader>
        <ModalBody>
        
Le Produit Sera Supprimée Définitivement
        </ModalBody>
        <ModalFooter>
          <Button color="btn btn-danger" onClick={(e)=>deleteProduct(uuid)}>Supprimer <FontAwesomeIcon icon={faTrashAlt} style={{color:"#FFFFFF"}}/> </Button>
          <Button color="secondary" onClick={(e)=>closeModal()}>Annuler</Button>
        </ModalFooter>
      </Modal>
     
  </tbody>
</table>
              </div>
        </div>
    )
}

export default GestProduct

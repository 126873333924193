import React,{useState,useEffect} from 'react';
import Axios from 'axios';
import "react-responsive-carousel/lib/styles/carousel.min.css";  
import { Carousel } from 'react-responsive-carousel';
import Loading from './Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faFilePdf} from "@fortawesome/free-solid-svg-icons";
import { UncontrolledCollapse, CardBody, Card } from 'reactstrap';
import { HashLink as Link } from 'react-router-hash-link';
 
function ProduitDisc({match}) {
    const [loading, setLoading]=useState(true)
    const [prod,setProd]= useState([])
    const [uuid, setUuid]=useState("")
    const [id, setId]=useState("")
     
    useEffect(()=>{
        Axios.get(`/frontend/get-all-prod/${match.params.uuid}`)
             .then(res=>{
                 setProd(res.data);
                 setUuid(res.data[0].Subcategory.Category.uuid)
                 setId(res.data[0].Subcategory.uuid)
                 setLoading(false);
            })
             .catch(error=>{console.log(error.message)})
    },[])
    const [pics,setPics]= useState([])
    useEffect(()=>{
        Axios.get(`/frontend/get-prod-pics/${match.params.uuid}`)
             .then(res=>{
                 setPics(res.data);
                 
            })
             .catch(error=>{console.log(error.message)})
    },[])
    
    return (
        
        <>
         <section id="toppage">
	 <nav>
		<ol className="cd-breadcrumb custom-separator">
			      <li><Link className="breadcrumb-link" to="/">Accueil</Link></li>
            <li><Link className="breadcrumb-link"  to="/produit">Produits</Link></li> 
            <li><Link className="breadcrumb-link" to={`/${match.params.matchcatName}/${uuid}`}>{match.params.matchcatName}</Link></li> 
            <li><Link className="breadcrumb-link" to={`/${match.params.matchcatName}/${match.params.matchsubcatName}/${id}`}>{match.params.matchsubcatName}</Link></li> 
            <li className="current"><em>{match.params.prodName}</em></li>
          	</ol>
	</nav>
</section>
        <div className="container-pp container mb-3"  >
              
         {loading ? <Loading/> : prod.map((val,index)=>(
         
          <div className="row mt-5" key={index}>
          <div className="col-md-4 col-lg-4">
          <Carousel infiniteLoop useKeyboardArrows autoPlay  >
                   {pics.map((item,key)=>(
                   <div key={key}>
                        <img alt="produit" src={`../../../imgs/${item.imgPath}`} style={{width:"100%",height:"75%"}} />
                         
                    </div>)) }
                    
                     
                </Carousel>
          </div>
          <div className="col-md-8 col-lg-8" >
              <h1 className="font">{val.productName}</h1>
            <p className="text-left gst-intro" style={{whiteSpace:"pre-line"}} >{val.productDescription} </p>
<ul className="ml-5"  >
    <p className="text-left gst-intro" style={{whiteSpace:"pre-line"}} >{val.characteristics}</p>
    </ul>
    
            <br/>
            
             <div>
    <div id="toggler" style={{ marginBottom: '1rem', color:"#FF2B3A", cursor:"pointer" }}>
    Documentations 
    </div>
    <UncontrolledCollapse toggler="#toggler">
      <Card>
        <CardBody>
        <div>Fiche Technique : <a href={`../../../files/${val.ficheTechnique}`} download> <FontAwesomeIcon icon={faFilePdf}  style={{width:'5%',height:"5%",color:"#FF2B3A"}} /></a></div>
        <div>  Brochure : <a href={`../../../files/${val.brochure}`} download> <FontAwesomeIcon icon={faFilePdf}  style={{width:'5%',height:"5%",color:"#FF2B3A"}} /></a></div>
             
        </CardBody>
      </Card>
    </UncontrolledCollapse>
  </div>
          </div>
          </div>))}
          </div>
       </>
    )
}

export default ProduitDisc

import React,{useState, useEffect} from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Axios from 'axios';
import { useHistory } from "react-router-dom";
const schema = yup.object().shape({
  page:yup
    .string()
    .required("ce champ est obligatoire"),
})

export default function FormBanner({preloadedValues, match}) {
    const history = useHistory();
const { register, handleSubmit, errors } = useForm({
    defaultValues: preloadedValues,
    resolver: yupResolver(schema)
  });
  const onSubmit = async (data,e) => {
    e.preventDefault();
    const updatedCat = new FormData()
    updatedCat.append('imgPath', data.banner[0])
    updatedCat.append("page",data.page)
     
     await Axios.put(`/banner/edit/${match}`,updatedCat)
    .then((res)=>{
            console.log(res.data);
            history.push("/gest-banner");
        })
    .catch(err=>console.log(err.message))
   
    };
    const [bannerPage, setBannerPage]=useState({
        page:"",
    })
    useEffect(() => {
        Axios.get(`/banner/get-one/${match}`)
        .then(res=>setBannerPage(res.data[0]))
        .catch((err)=>console.log(err))
    }, [])
   const onChange = (e) => {
       setBannerPage({...bannerPage, [e.target.name]: e.target.value});
     }
    return (
        <div className="container mt-5">
              
                 <div className="col-md-8 col-lg-8">
                 <h1>Modifier Image De Couverture  </h1><br/>
                
                 <form onSubmit={handleSubmit(onSubmit)}  encType="multipart/form-data"  method="post">
                 <div className='form-group mb-4 mt3'>
                    <label>Page</label>
                    <select   className={`${errors.page? `form-control is-invalid`: `form-control` }`} name="page" value={bannerPage.page} onChange={onChange} ref={register}>
                    <option value="entreprise">Page Entreprise</option>
                    <option value="produit">Page Produits</option>
                    <option value="service">Page Services</option>
                    </select>
                    <div className="error ">{errors.page?.message}</div>
                    </div>
                    <div className='custom-file mb-4 mt-4 col-md-6 col-lg-6'>
               <label>Image</label>
           <input
            type='file'
            className={`${errors.banner? `form-control is-invalid`: `form-control` }`}
            id='customFile'
            name="banner"
            ref={register} 
            />
            <div className="error ">{errors.banner?.message}</div>
             </div>
        <div className='custom-file  mt-3 mb-1 col-md-6 col-lg-6  text-right' >
           <div>
               <img src={`../../imgs/${preloadedValues.imgPath}`} style={{width:'40%',height:"40%"}} alt=""  name="bannerimgPath" ref={register}/>
           </div>
         
         
        </div>
        <div className="form-group">
        <button type="submit" className="btn  mt-3" style={{backgroundColor:"#FF2B3A",color:'#fff'}} >Modifier</button>

             </div>
        
                    </form>
                    </div>
                    </div>
    )
}

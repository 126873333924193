import React from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Axios from 'axios';
import { useHistory } from "react-router-dom";

const schema = yup.object().shape({
  catName:yup
  .string()
  .required("ce champ est obligatoire"),
  intro:yup
  .string()
  .max(200, "ce champ doit comporter au maximum 200 caractères")
  .required("ce champ est obligatoire")
});

export function FormCat({preloadedValues, match}) {
     
    const history = useHistory();
    const { register, handleSubmit, errors } = useForm({
        defaultValues: preloadedValues,
        resolver: yupResolver(schema)
      });
      const onSubmit = async (data,e) => {
        e.preventDefault();
        const updatedCat = new FormData()
        updatedCat.append('catImg', data.img[0])
        updatedCat.append("banner",data.banner[0])
        updatedCat.append("catName",data.catName)
        updatedCat.append("intro",data.intro)
         await Axios.put(`/editcat/${match}`,updatedCat)
        .then((res)=>{
                console.log(res.data);
                history.push("/gest-cat");
            })
        .catch(err=>console.log(err.message))
        };
    return (
        <div className="container mt-5">
             <div className="row row-a">
                 <div className="col-md-8 col-lg-8">
                     <h1>Modifier Catégorie </h1><br/>
                  <form   onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data"  >
                      
                     <div className="form-group">
                         <label htmlFor="name">nom</label>
                          <input type="text" className={`${errors.catName? `form-control is-invalid`: `form-control` }`} id="name" name="catName" ref={register}  />
                          <div className="error ">{errors.catName?.message}</div>
                       </div>
                       <div className="form-group">
                         <label htmlFor="name">intro</label>
                          <textarea className={`${errors.intro? `form-control is-invalid`: `form-control` }`} id="name" name="intro"  ref={register}  />
                          <div className="error ">{errors.intro?.message}</div>
                       </div>
                        <div className='custom-file mb-4 col-md-6 col-lg-6'>
                        <label>Image </label>
                        <input
                             type='file'
                             className={`${errors.img? `form-control is-invalid`: `form-control` }`}
                             id='customFile'
                             name="img"
                             ref={register}
                              />
                              <div className="error ">{errors.img?.message}</div>
                               </div>
        <div className='custom-file  mt-3 mb-5 col-md-6 col-lg-6  text-right' >
           <div>
               <img src={`../../imgs/${preloadedValues.imgPath}`} style={{width:'40%',height:"40%"}} alt="" name="imgPath" ref={register}/>
           </div>
         
         
        </div>
                      
        <div className='custom-file mb-4 mt-4 col-md-6 col-lg-6'>
        <label>Image De Couverture</label>
           <input
            type='file'
            className={`${errors.banner? `form-control is-invalid`: `form-control` }`}
            id='customFile'
            name="banner"
            ref={register}/>
            <div className="error ">{errors.banner?.message}</div>
             </div>
        <div className='custom-file  mt-3 mb-1 col-md-6 col-lg-6  text-right' >
           <div>
               <img src={`../../imgs/${preloadedValues.bannerimgPath}`} style={{width:'40%',height:"40%"}} alt=""  name="bannerimgPath" ref={register}/>
           </div>
         
         
        </div>
         <button type="submit" className="btn  mt-3" style={{backgroundColor:"#FF2B3A",color:'#fff'}} >Modifier</button>
                </form>
                </div>
                </div>
                </div>
    )
}

  
 
import React,{useState, useEffect} from 'react'
import Axios from 'axios';
import Loading from '../../Loading';
import FormTeam from './FormTeam';
 

function EditTeam({match}) {
    const [team, setTeam]= useState(null)
    useEffect(() => {
        Axios.get(`/team/get-one/${match.params.uuid}`)
                    .then((result)=>setTeam(result.data[0]))
                    .catch((error)=>console.log(error.message));
                }, [match.params.uuid])
    return  team ? <FormTeam preloadedValues={team} match={match.params.uuid}/> :  <Loading/>
}

export default EditTeam

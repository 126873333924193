import React ,{useState,useEffect} from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit} from "@fortawesome/free-solid-svg-icons";
import Axios from "axios";

function GestTeam() {
    const[teams, setTeams]=useState([])
    useEffect(() => {
        Axios.get("/team/get-all")
             .then(res=>setTeams(res.data))
             .catch(err=>console.log(err.message))
    }, [])
    return (
        <div className="container mt-5">
        <div className="col-lg-12 col-md-12 d-flex justify-content-between" style={{backgroundColor:'#c9c9c9'}}>
         <h1 className="justify-content-start" style={{color:'#000'}}>List d'equipe</h1>
          </div>
             <div className="col-lg-12 col-md-12 ">
             <table class="table table-hover">
  <thead>
    <tr>
      <th scope="col">Poste</th>
      <th scope="col">Email</th>
      <th scope="col">Tel</th>
      <th scope="col">Actions</th>
    </tr>
  </thead>
  <tbody>
    {
     teams.map((item,key)=>(<tr key={key}>
      <th scope="row">{item.poste}</th>
      <th scope="row">{item.email}</th>
      <th scope="row">{item.phoneNumber}</th>
       <td>
         <Link to={`/gest-equipe/edit-equipe/${item.uuid}`} style={{color:"inherit"}}>  <button className="btn btn-info" >Modifier <FontAwesomeIcon icon={faEdit} style={{color:"#fff"}}/> </button></Link> </td> 
       
    </tr>))}
    
  </tbody>
</table>
             </div>
             </div>
    )
}

export default GestTeam

import React,{useReducer,useEffect} from 'react';
import Axios from 'axios';
import Loading from './Loading';
import { HashLink as Link } from 'react-router-hash-link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";
const initialState={
    loading:true,
    error:'',
    cat:{}
}
const reducer=(state,action)=>{
  switch(action.type){
      case "fetch success":
          return {
              loading:false,
              cat: action.payload,
              error:''
          }
     case "fetch error":
         return {
             loading:false,
             cat: {},
             error: "something went wrong "
         }
      default :
        return state
  }
}
 
function ProduitPage() {
    const [state,dispatch]= useReducer(reducer , initialState)
    useEffect(()=>{
        Axios.get(`/frontend/get-all-cat`)
             .then(res=>{
                 dispatch({type:"fetch success", payload:res.data})
                 })
             .catch(error=>{
                  dispatch({type:"fetch error"})
             })
    },[ ])
     
    return (
        <> 
        
 
<section>
	 <nav>
		<ol className="cd-breadcrumb custom-separator">
			<li><Link className="breadcrumb-link" to="/">Accueil</Link></li>
			 <li className="current"><em>Produits</em></li>
		</ol>
	</nav>
</section>

 

 
<div className="container-fluid" id="toppage">
 
<div className="row mx-md-5 mx-lg-5 my-md-5 pb-md-5 my-lg-0 my-0">

      {
        state.loading ? <Loading/> : state.cat.map(value=>(  
        <div className="col-md-4 col-lg-4 mt-5   products" key={value.uuid}>
        <Link style={{color:"#000000"}} to={`/${value.catName}/${value.uuid}`}> 
        <img src={`../../imgs/${value.imgPath}`} alt="produits"   className="products-img"/>
        </Link>
            <hr className="produit"/> 
            <Link style={{color:"#000000"}} to={`/${value.catName}/${value.uuid}`}>
            <h3 className="font h4">{value.catName}</h3></Link> 
            <p className="text-left  ">{value.intro} </p>
             <Link style={{color:"#000000"}} to={`/${value.catName}/${value.uuid}`}>
                En Savoir Plus 
             <FontAwesomeIcon icon={faArrowRight} style={{color:"#FF2B3A"}}/>
             </Link> 
             <hr className="separte"/>
            </div>
                 
                )) }
       
    
       
  </div>
</div>
 
 
  </>  
    )
}

export default ProduitPage

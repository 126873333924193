import React,{useState, useEffect} from 'react'
import Axios from 'axios';
 
import { FormCat } from './FormCat';
import Loading from '../../Loading';

 

function EditCat({match}) {
     
     
    const [cat,setCat]=useState(null)
    
    useEffect(() => {
    Axios.get(`/category/get-one/${match.params.uuid}`)
                .then((result)=>setCat(result.data[0]))
                .catch((error)=>console.log(error.message));
            }, [match.params.uuid])
    
        return  cat ? <FormCat preloadedValues={cat} match={match.params.uuid}/> :  <Loading/> 

   
}

export default EditCat

import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook,faInstagram,faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import {faPhoneAlt,faMapMarkerAlt,faEnvelope } from "@fortawesome/free-solid-svg-icons";
 import {NavLink} from 'react-router-dom';
 
function Footer() {
    return (
         
            <footer className="footer-section   mt-5 pt-5 mt-md-5 mt-lg-0    mx-3">
        
            <div className="footer-cta  ">
                <div className="row">
                    <div className="col-lg-4 col-md-4 col-12 my-2" style={{display:"flex"}}>
                    <FontAwesomeIcon icon={faMapMarkerAlt}   size="2x" style={{color:"#FF2B3A"}}/>
                    <div className="cta-text">
                               <h4 >Trouvez-nous</h4>
                                <span>Rue Teimour, Immeuble Barghou 3, Appt. N°22 El Menzeh 1 1004 Tunis, Tunisia</span>
                            </div> 
                    </div>
                    <div className="col-lg-4 col-md-4 col-12 my-2" style={{display:"flex"}}>
                    <FontAwesomeIcon icon={faPhoneAlt} size="2x" style={{color:"#FF2B3A"}}/>
                    <div className="cta-text">
                                <h4>Appelez-nous</h4>
                                <span>+216 71 755 330</span>
                            </div> 
                    </div>
                    <div className="col-lg-4 col-md-4 col-12 my-2" style={{display:"flex"}}>
                    <FontAwesomeIcon icon={faEnvelope} size="2x" style={{color:"#FF2B3A"}}/>
                    <div className="cta-text">
                                <h4>Contactez-nous</h4>
                                <span>infos.gst@geosystems.com.tn</span>
                            </div> 
                    </div>
                </div>
            </div>
            <div className="footer-content pt-3">
                <div className="row">
                    <div className="col-12 col-md-6 col-lg-6">
                        <div className="footer-widget">
                            <div className="footer-logo">
                                <NavLink to="/"><img src="../../../imgs/logo.png" className="img-fluid" alt="logo"/></NavLink>
                            </div>
                            <div className="footer-text">
                                <p className="text-justify">Geosystems Tunisie
est le représentant & le distributeur agréé par Leica Geosystems en Tunisie .</p>
                            </div>
                           
                       
                    </div>
                    
                        </div>
                        <div className="col-12 col-lg-6 col-md-6  ">
                        <div className="footer-widget">
                            <div className="footer-widget-heading">
                                <h3>Liens Utiles</h3>
                            </div>
                            <ul>
                             
                                <li><NavLink  to={{pathname:"/entreprise"}}>Découvrez-Nous</NavLink></li>
                                <li><NavLink  to={{pathname:"/produit"}}>Produit</NavLink></li>
                                <li><NavLink  to={{pathname:"/service"}}>service</NavLink></li>
                                <li><NavLink  to={{pathname:"/contact"}}>Contact</NavLink></li>
                                 
                            </ul>
                            
                        </div>
                      
                    </div>

                     </div>
                     
            </div>
            <div className="footer-content">
            <div className="row">
                     <div className="col-6 col-md-6 col-lg-6">
                       
                       <div className="footer-social-icon">
                       <div className="footer-widget-heading"><h3>Suivez-nous sur</h3> </div>
                                   <a href="//www.facebook.com/GeosystemsTunisia"  rel="noreferrer" target="_blank"><FontAwesomeIcon icon={faFacebook}  size="2x"/></a>
                                   <a href="//www.instagram.com/leicageosystemstunisie/" rel="noreferrer"  target="_blank"><FontAwesomeIcon icon={faInstagram} size="2x"  /></a>
                                   <a href="//www.linkedin.com/company/geosystems-tunisia/" rel="noreferrer"  target="_blank"><FontAwesomeIcon icon={faLinkedinIn}  size="2x"/> </a>
                               </div>
                           </div>
                           <div className="col-6 col-md-6 col-lg-6 my-auto">
                            
                               <img src="/imgs/footer-leica.jpg" className="img-fluid footer-leica-img"  alt="leica geosysytems" />
                            
                           </div>
                     </div>
                     <div className="col-12 col-md-12 col-lg-12">
                     <div className="copyright-text ">
                            <p>Copyright © G.S.T {new Date().getFullYear()}. All right reserved - Propulsé par <a href="https://www.seek4digital.com/" target="_blank">Seek4Digital</a>.</p>
                        </div>
                     </div>
            </div>
         
        <div className="copyright-area mb-2 mt-4">
                <div className="fixed-tabs-Whats">
  <div className="aba-whatsphone">
    <div className="aba-whatsphone-icone" style={{backgroundImage:"url(/imgs/whatsapp.png)"}}>
      <a  style={{textDecoration:"none",color:"#FFFFFF"}} href="tel:+21671755330">Besoin D'aide ? <br/><strong>+216 71 755 330</strong></a>          
    
    </div>
  </div>          
</div>
                
        </div>
 
    </footer>
         
    )
}

export default Footer

import React,{useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope,faPhoneAlt,faFax } from "@fortawesome/free-solid-svg-icons";
import Axios from 'axios';
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Message from './auth/Message';
import { HashLink as Link } from 'react-router-hash-link';

const schema = yup.object().shape({
    name:yup
    .string()
    .required("ce champ est obligatoire"),
    email:yup
    .string()
    .email("l'email doit être un email valide")
    .required("ce champ est obligatoire"),
    subject:yup
    .string()
    .required("ce champ est obligatoire"),
    msg:yup
    .string()
    .required("ce champ est obligatoire"),
});
function ContactPage() {
    const history = useHistory();
     const [message, setMessage] = useState('');
     const { register , handleSubmit, errors } = useForm({
        resolver: yupResolver(schema),
       
      }) 
      const onSubmit = async (data,e)=>{
        e.preventDefault();
        const newEmail = new FormData();
        newEmail.append('name', data.name);
        newEmail.append('email',data.email);
        newEmail.append('subject',data.subject)
        newEmail.append("msg",data.msg)
       
        try {
          await Axios.post('/frontend/new-email', newEmail)
           
          .then(res=>{
              
          }).catch(err=>{
           
           });
          setMessage("E-mail envoyé avec succès");
          setTimeout(() => setMessage(''), 1000);
          setTimeout(() => document.getElementById('myform').reset(),1100);
          setTimeout(() => history.push("/"),1300)
          } catch (err) {
          if (err.response.status === 500) {
           setMessage("Il y a eu un problème avec le serveur, veuillez renvoyer l'e-mail");
           } else {
          setMessage(err.response.data.msg);
          }
         }
     
    }
    return (
        <>
         <section id="toppage">
	 <nav>
		<ol className="cd-breadcrumb custom-separator">
			<li><Link className="breadcrumb-link" to="/">Accueil</Link></li>
			 <li className="current"><em>Contact</em></li>
		</ol>
	</nav>
</section>
        <div className="container" >
            <div className="row my-5">
            <div className="col-md-6 col-lg-6 ">
                <h3 className="font">Besoin de plus d'information? Communiquez avec nous.</h3>
                <div className="gst-intro"><a href="mailto:infos.gst@geosystems.com.tn"  style={{color:"inherit",textDecoration:"none"}}><span  aria-hidden="true"><FontAwesomeIcon icon={faEnvelope} size="1x" style={{color:"#FF2B3A"}}  /></span> infos.gst@geosystems.com.tn</a><br/>
                <a href="tel:+21671755330" style={{color:"inherit",textDecoration:"none"}}><span  aria-hidden="true"><FontAwesomeIcon icon={faPhoneAlt} size="1x" style={{color:"#FF2B3A"}}  /></span> + 216 71 755 330</a><br/>
                <a  style={{color:"inherit"}}><span  aria-hidden="true"><FontAwesomeIcon icon={faFax} size="1x" style={{color:"#FF2B3A"}}  /></span> + 216 71 755 003</a>
                </div>
                <div>
                    <ul className="gst-intro" style={{listStyleType:"none"}}>
                        <li><h3 className="font mb-3 mt-2">Service Commercial </h3> 
                           <a href="mailto:Mehdi.gst@geosystems.com.tn" style={{color:"inherit",textDecoration:"none"}}><span  aria-hidden="true"><FontAwesomeIcon icon={faEnvelope} size="1x" style={{color:"#FF2B3A"}}  /></span> Mehdi.gst@geosystems.com.tn</a> <br/>
                           <a href="tel:+21671755330" style={{color:"inherit"}}><span  aria-hidden="true"><FontAwesomeIcon icon={faPhoneAlt} size="1x" style={{color:"#FF2B3A"}}  /></span> + 216 71 755 330</a><br/>

                        </li>
                    </ul>
                    <ul className="gst-intro" style={{listStyleType:"none"}}>
                        <li><h3 className="font mb-3 mt-2">Service Après Vente </h3> 
                           <div >
                           <a href="mailto:Hamdi.gst@geosystems.com.tn" style={{color:"inherit"}}><span  aria-hidden="true"><FontAwesomeIcon icon={faEnvelope} size="1x" style={{color:"#FF2B3A"}}  /></span> Hamdi.gst@geosystems.com.tn</a> <br/>
                            </div>
                           <div className="mt-3 ">
                           <a href="mailto:Alaa.gst@geosystems.com.tn" style={{color:"inherit"}}><span  aria-hidden="true"><FontAwesomeIcon icon={faEnvelope} size="1x" style={{color:"#FF2B3A"}}  /></span> Alaa.gst@geosystems.com.tn</a> <br/>
                            </div>
                          <div className="mt-3">
                          <a href="mailto:Mehdi.gst@geosystems.com.tn" style={{color:"inherit"}}><span  aria-hidden="true"><FontAwesomeIcon icon={faEnvelope} size="1x" style={{color:"#FF2B3A"}}  /></span> Mehdi.gst@geosystems.com.tn</a> <br/>
                           </div>

                        </li>
                    </ul>
                    
                </div>

            </div>
                <div className="col-md-6 col-lg-6 ">
                    <div>
                    
                    <iframe 
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d282.24541034285807!2d10.174620089135512!3d36.836672759922315!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12fd35cdaf9c2631%3A0x4139fa936da30990!2sGeosystems%20Tunisie!5e0!3m2!1sen!2stn!4v1619771858174!5m2!1sen!2stn" 
                    style={{width:"100%"}} id="map" allowFullScreen="" loading="lazy"></iframe>
                </div>
                {message ? <Message msg={message} /> : null}
                <form onSubmit={handleSubmit(onSubmit)} id="myform" className="mt-3">
                    <div className="form-group">
             <label htmlFor="nom">Votre nom complet</label>
              <input type="text" className={`${errors.name? `form-control is-invalid`: `form-control` }`} id="nom" aria-describedby="nom" name="name" ref={register} />
              <div className="error ">{errors.name?.message}</div>
              </div>
           <div className="form-group">
             <label htmlFor="exampleInputEmail1">Votre Courriel</label>
              <input type="text" className={`${errors.email? `form-control is-invalid`: `form-control` }`} id="exampleInputEmail1" aria-describedby="emailHelp" name="email" ref={register}  />
               <div className="error ">{errors.email?.message}</div>
               
              </div>
              <div className="form-group">
             <label htmlFor="sujet">Sujet</label>
              <select type="text" className={`${errors.subject? `form-control is-invalid`: `form-control` }`} id="sujet" aria-describedby="sujet" name="subject" ref={register}>
                  <option>Offre de Prix</option>
                  <option>Maintenance & Réparation</option>
                  <option>Formation</option>
                  <option>Autre</option>

             </select>
             <div className="error ">{errors.subject?.message}</div>
              </div>
              <div className="form-group">
                  <label htmlFor="message">Message</label>
                  <textarea className={`${errors.msg? `form-control is-invalid`: `form-control` }`} id="message" rows="3" name="msg" ref={register}/>
              <div className="error ">{errors.msg?.message}</div></div>
  
  <button type="submit" className="btn btn-secondary">Envoyer</button>
 
</form>
                </div>
            </div>
        </div>
        </>
    )
}

export default ContactPage

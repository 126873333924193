import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';  
import { Auth0Provider } from '@auth0/auth0-react';

const domain ="dev-lp5nzyo6.us.auth0.com"
const clientID="tNBFME0nTrN9xENhx0hyewttcDXXAtYD"

ReactDOM.render(
  <Auth0Provider
  domain={domain}
  clientId={clientID}
  redirectUri={"https://www.geosystems-tunisie.com/gest-cat" }
  >
    <App />
  </Auth0Provider>,
  document.getElementById('root')
);



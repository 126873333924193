import React from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Axios from 'axios';
import { useHistory } from "react-router-dom";

const schema = yup.object().shape({
    name:yup
    .string()
    .required("ce champ est obligatoire"),
    intro:yup
    .string() 
    .required("ce champ est obligatoire"),
    link:yup
    .string() 
    .required("ce champ est obligatoire"),
    ordre:yup
    .string() 
    .required("ce champ est obligatoire"),
});
export  function FormBlock({preloadedValues, match}) {
    const history = useHistory();
    const { register, handleSubmit, errors } = useForm({
        defaultValues: preloadedValues,
        resolver: yupResolver(schema)
      });
      const onSubmit = async (data,e) => {
        e.preventDefault();
        const updatedBlock = new FormData()
        updatedBlock.append('imgPath', data.img[0])
        updatedBlock.append('name',data.name)
        updatedBlock.append('intro',data.intro)
        updatedBlock.append("link",data.link)
        updatedBlock.append("ordre",data.ordre)
         await Axios.put(`/home/edit/${match}`,updatedBlock)
        .then((res)=>{
                history.push("/gest-home");
            })
        .catch(err=>console.log(err.message))
        };
    return (
        <div className="container mt-5">
              
        <div className="col-md-8 col-lg-8">
            <h1>Ajouter Block </h1>
        <form onSubmit={handleSubmit(onSubmit)}  encType="multipart/form-data"  method="post">
            <div className="form-group">
           
                <label htmlFor="name">Titre</label>
                 <input type="text" className={`${errors.name? `form-control is-invalid`: `form-control` }`} id="name" name="name" aria-describedby="emailHelp" ref={register}  />
                 <div className="error ">{errors.name?.message}</div>
              </div>
              <div className="form-group">
           
                <label htmlFor="name">Intro</label>
                 <textarea type="text" className={`${errors.intro? `form-control is-invalid`: `form-control` }`} id="name" name="intro" aria-describedby="emailHelp" ref={register}  />
                 <div className="error ">{errors.intro?.message}</div>
              </div>
              <div className="form-group">
           
                <label htmlFor="name">Lien</label>
                 <textarea type="text" className={`${errors.link? `form-control is-invalid`: `form-control` }`} id="name" name="link" aria-describedby="emailHelp" ref={register}  />
                 <div className="error ">{errors.link?.message}</div>
              </div>
              <div className="form-group">
           
                <label htmlFor="name">Ordre</label>
                 <input type="text" className={`${errors.ordre? `form-control is-invalid`: `form-control` }`} id="name" name="ordre" aria-describedby="emailHelp" ref={register}  />
                 <div className="error ">{errors.ordre?.message}</div>
              </div>
              
              <div className='custom-file mb-4 col-md-6 col-lg-6'>
                        <label>Image </label>
                        <input
                             type='file'
                             className={`${errors.img? `form-control is-invalid`: `form-control` }`}
                             id='customFile'
                             name="img"
                             ref={register}
                              />
                              <div className="error ">{errors.img?.message}</div>
                               </div>
        <div className='custom-file  mt-3 mb-5 col-md-6 col-lg-6  text-right' >
           <div>
               <img src={`../../imgs/${preloadedValues.imgPath}`} style={{width:'40%',height:"40%"}} alt="" name="imgPath" ref={register}/>
           </div>
         
         
        </div>
  
           <button type="submit" className="btn" style={{backgroundColor:"#FF2B3A",color:'#fff'}}  >Ajouter</button>
       </form>
       </div>
       </div>
    )
}

import React,{useState, useEffect} from 'react';
import Axios from 'axios';
import Loading from './Loading';
import { HashLink as Link } from 'react-router-hash-link';


function ServicePage() {
    const [loading, setLoading]= useState(true);
    const[banner, setBanner]= useState([])
    useEffect(() => {
        const page= new FormData();
        page.append("banner","service");
        Axios.post("/frontend/get-banner",page)
             .then(response =>{
                 setBanner(response.data);
                 setLoading(false)})
             .catch(error =>console.log(error.message))
        
    }, [])
    return (
        <>
         <section id="toppage">
	 <nav >
		<ol className="cd-breadcrumb custom-separator ">
			<li><Link className="breadcrumb-link" to="/">Accueil</Link></li>
			 <li className="current"><em>Services</em></li>
		</ol>
	</nav>
</section>
        <div className="large-header" id="toppage">
        {
            loading ? <><Loading/></> :
            <>{banner.map(item=>(
            <img key={item.uuid} 
            id="img-banner" 
            src={`../../imgs/${item.imgPath}`} 
            alt="gst-produit" 
            style={{width:"100%"}}/>))}</>
        }
        </div>
        <div className="container-fluid">
           <div className="row mx-md-5 mx-lg-5 mt-5" id="maintenance"> 
          <div className="col-lg-6 col-md-12 bg-gris" >
              <h3 className="font text-left font">Maintenance et Réparation</h3>
          <p className="text-left gst-intro">
          Service Technique autorisé par LEICA GEOSYSTEMS possède tout l’équipement nécessaire pour assurer une vérification rapide de vos instruments et qui permet à nos techniciens qualifiés de procéder à l’entretien complet et à la réparation des instruments du manufacturier Leica Geosystems.
          </p>
          <ul className="ml-5 gst-intro">
              <li>
              Centre de service autorisé Leica Geosystems
              </li>
              <li>
              Service d’inspection, de calibration et de certification annuelle
              </li>
              <li>
              Les maintenances de votre matériel qui peuvent être inclus dans votre Contrat de Services Client (CCP) 
              </li>
              <li>
              Réparations              
             </li>
             <li>
             Techniciens expérimentés et formés en continu
             </li>
          </ul>
           
          </div>
          <div className="col-lg-6 col-md-12 bg-gris d-flex flex-row-reverse">
        
              <img className="w-100 h-100" src="../../imgs/maintenance-01.png" alt="Maintenance et Réparation"/>
         
          </div>
          
          </div>


          <div className="row mx-md-5 mx-lg-5 mt-5" id="supportTechnique"> 
          <div className="col-lg-6 col-md-6 order-md-2 " >
              <h3 className="font text-left font">Support technique</h3>
          <p className="text-left gst-intro">
          Votre question ou problématique sera traitée rapidement par un membre de notre équipe support hautement qualifié. 
          <br/>
          Contactez-nous : +216 71 755 330

          </p>
           
          </div>
          <div className="col-lg-6 col-md-6 ">
        
              <img className="w-100 h-100" src="../../imgs/support-01.png" alt="Support technique"/>
         
          </div>
          
          </div>
          <div className="row mx-md-5 mx-lg-5 mt-5 mb-3" id="formation"> 
          <div className="col-lg-6 col-md-12 bg-gris" >
              <h3 className="font text-left font">Formation et accompagnement</h3>
          <p className="text-left gst-intro">
          Soyez productif rapidement grâce aux formations à la carte sur nos solutions de pointe avec nos ingénieurs          
           </p>
           <p className="text-left gst-intro">
           Suivant l’achat ou la location d’instruments spécialisés, GST se distingue en vous accompagnant étroitement,Peu importe votre maîtrise de ces instruments, l’équipe  s’assurera de vous offrir tout le support nécessaire pour permettre à votre entreprise d’être tout à fait autonome dans l’utilisation qu’elle désire en faire.
           </p>
          <ul className="ml-5 gst-intro">
              <li>
              Formations et demonstrations
              </li>
              <li>
              Présence en chantier
              </li>
              <li>
              Acquérir une compétence rapidement et rentabiliser son investissement
              </li>
              <li>
              Gagner du temps et de l’autonomie              
             </li>
             <li>
             Uniformiser vos méthodologies de terrain et de bureau
             </li>
             <li>
             Formation en ligne
             </li>
          </ul>
           
          </div>
          <div className="col-lg-6 col-md-12 bg-gris d-flex flex-row-reverse">
        
              <img className="w-100 h-100" src="../../imgs/formation-01.png" alt="Formation et accompagnement"/>
         
          </div>
          
          </div>
          
          </div>

        
        

      
          </>
 

    )
}

export default ServicePage

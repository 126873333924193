import React,{useState,useEffect} from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faTrashAlt,faEdit} from "@fortawesome/free-solid-svg-icons";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Axios from "axios"

function GestCarousel() {
    const [carousel, setCarousel]=useState([])
    useEffect(() => {
        Axios.get("/carousel/get-all")
              .then(response =>setCarousel(response.data))
              .catch(error => console.log(error.message))
      
    }, [])
    const [modal, setModal] = useState(false);
    const [uuid, setUuid]= useState("")
  
    const toggle = (uuid) => {
     setModal(!modal);
     setUuid(uuid);  }
     const closeModal=()=>{
       setModal(!modal);
     }
     const deleteCarousel=async (uuid,e)=>{
        await  Axios.delete(`/carousel/delete/${uuid}`);
         setCarousel(prevCarousel=>prevCarousel.filter(val=> val.uuid !== uuid));
         setModal(false);
        }
    return (
        <div className="container mt-5">
        <div className="col-lg-12 col-md-12 d-flex justify-content-between" style={{backgroundColor:'#c9c9c9'}}>
         <h1 className="justify-content-start" style={{color:'#000'}}>Ajouter Image Carousel</h1>
          <Link to="/gest-caroussel/add-carousel" style={{color:"inherit", marginTop:"1%"}}><button className="btn   justify-content-end" style={{backgroundColor:"#FF2B3A",color:"#fff"}}>Ajouter </button></Link>
         </div>
         <div className="col-lg-12 col-md-12 ">
         <table className="table table-hover">
<thead>
<tr>
 <th scope="col">Lien</th>
 <th scope="col">image</th>
 <th scope="col">Actions</th>
  
</tr>
</thead>
<tbody>
{ carousel.map(item=>(
    <tr key={item.uuid} >
    <th style={{width:"25%" }} scope="row">
     {item.link.split("/").join("=>")}
    </th>
    <td style={{width:"25%"}}><div><img src={`../../imgs/${item.imgPath}`} style={{width:"20%" , height: "1%"}} alt="carousel img"/></div> </td>
    <td><button className="btn btn-danger" onClick={(e)=>toggle(item.uuid)} >Supprimer <FontAwesomeIcon icon={faTrashAlt} style={{color:"#FFFFFF"}}/> </button> <Link to={`/gest-caroussel/edit-carousel/${item.uuid}`} style={{color:"inherit"}}>  <button className="btn btn-info" >Modifier <FontAwesomeIcon icon={faEdit} style={{color:"#fff"}}/> </button></Link> </td> 
    </tr>
))
}
<Modal isOpen={modal} uuid={uuid} toggle={(e)=>closeModal}>
        <ModalHeader toggle={closeModal}>Vous êtes sûr ? </ModalHeader>
        <ModalBody>
        
L'image Sera Supprimée Définitivement
        </ModalBody>
        <ModalFooter>
          <Button color="btn btn-danger" onClick={(e)=>deleteCarousel(uuid)}>Supprimer <FontAwesomeIcon icon={faTrashAlt} style={{color:"#FFFFFF"}}/> </Button>
          <Button color="secondary" onClick={(e)=>closeModal()}>Annuler</Button>
        </ModalFooter>
      </Modal>



</tbody>
</table>
         </div>
   </div>
    )
}

export default GestCarousel

import React ,{useState,useEffect} from 'react';
import Axios from 'axios';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
function CarouselClients() {
  const[clients, setClients]=useState([])
  useEffect(() => {
      Axios.get("/client/get-all")
           .then(res=>setClients(res.data))
           .catch(err=>console.log(err.message))
  }, [])
    
    return (
        <div className="container-fluid  ">
          <div className=" mx-md-5 mx-lg-5">
            <div className="heading">
                    <h3 className="head font"><span className="before-title mr-3"></span>Nos Références</h3>
                    
                      
                  </div>
         
        <Carousel
       additionalTransfrom={1}
       arrows={false} 
       centerMode={false}
      infinite
       draggable
       focusOnSelect={false}
       autoPlay
        autoPlaySpeed={2000}
       keyBoardControl
       minimumTouchDrag={80}
       renderButtonGroupOutside={false}
       renderDotsOutside={false}
       responsive={{
         desktop: {
           breakpoint: {
             max: 3000,
             min: 1024
           },
           items: 5,
            
         },
         mobile: {
           breakpoint: {
             max: 464,
             min: 180
           },
           items: 3,
           
         },
         tablet: {
           breakpoint: {
             max: 1024,
             min: 464
           },
           items: 5,
            
         }
       }}
       showDots={false}
       sliderClass="my-3" 
       swipeable
       slidesToSlide={3}
      >
           {clients.map((item,key)=>( 
           <div className="owl-item" key={key}>
           <div className="bbb_viewed_item discount d-flex flex-column align-items-center justify-content-center text-center">
               <div className="bbb_viewed_image">
                 
                   <img src={`../../imgs/${item.imgPath}`} alt="produits" />
                    
                   </div>
              
                
           </div>
       </div>  
            ))}
            
          </Carousel>
           
          </div>
          </div>
    )
}

export default CarouselClients

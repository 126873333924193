import React,{useReducer,useEffect,useState,Fragment} from 'react';
import Axios from 'axios';
import { HashLink as Link } from 'react-router-hash-link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";
import Loading from './Loading';
 
const initialState={
    loading:true,
    error:'',
    cat:{}
}
const reducer=(state,action)=>{
  switch(action.type){
      case "fetch success":
          return {
              loading:false,
              cat: action.payload,
              error:''
          }
     case "fetch error":
         return {
             loading:false,
             cat: {},
             error: "something went wrong "
         }
      default :
        return state
  }
}

function PageStationTotale({match}) {
     
    const [state,dispatch]= useReducer(reducer , initialState)
    useEffect(()=>{
        Axios.get(`/frontend/cat-page/${match.params.uuid}`)
             .then(res=>{
                 dispatch({type:"fetch success", payload:res.data});
                  
                 })
             .catch(error=>{
                  dispatch({type:"fetch error"})
             })
    },[match])
     
    
  
    return (
        <>
         <section id="toppage">
	 <nav>
		<ol className="cd-breadcrumb custom-separator">
			<li><Link className="breadcrumb-link" to="/">Accueil</Link></li>
            <li><Link className="breadcrumb-link" to="/produit">Produits</Link></li>
			 <li className="current"><em>{match.params.catName}</em></li>
		</ol>
	</nav>
</section>
        {state.loading ? <Loading/> : state.cat.map((val,index)=>(
        <div className="large-header" id="toppage" key={index}> 
      <img src={`../../imgs/${val.bannerimgPath}`} id="img-banner" alt="gst-produit" style={{width:"100%"}}/> 
       </div> ))
       }
   <div className="container-fluid">
       <div className="row  mx-md-5 mx-lg-5 mt-5">
  {state.loading ? <Loading/>:  <> 
    {
        state.cat.map((val,indexCat)=>(
        <Fragment key={indexCat}>
        {val.Subcategories.map((value,key)=>(
        <div className="col-md-4 col-lg-4 mt-5   products" key={key}> 
         
              <Link to={`/${val.catName}/${value.subcatName}/${value.uuid}`} style={{color:"#000000"}}>
           <img src={`../../imgs/${value.subcatimgPath}`} alt="produits"  className="products-img"/>
           </Link>
          <hr className="produit"/>             
          <Link to={`/${val.catName}/${value.subcatName}/${value.uuid}`} style={{color:"#000000"}}>
              <h3 className="font">{value.subcatName}</h3></Link>  
          <p className="text-left gst-intro" > {value.introsubCat}  </p>  
          <Link to={`/${val.catName}/${value.subcatName}/${value.uuid}`} style={{color:"#000000"}}>En Savoir Plus <FontAwesomeIcon icon={faArrowRight} style={{color:"#FF2B3A"}} size="1x"/> </Link>
          <hr className="separte"/>
      </div>))}</Fragment> 
             ))}
      
      
       </>}
        </div>
       </div>
       </>
       ) 
      
    
}

export default PageStationTotale
 
import React,{useState,useEffect} from 'react';
import Axios from 'axios';
import Message from '../Message';
import Progress from "../Progress";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
const schema = yup.object().shape({
    img: yup
      .mixed()
      .required("ce champ est obligatoire")
      .test("type", "ce champ doit être image de type jpeg or png", (value) => {
        return (value && value[0].type === "image/jpeg") || (value && value[0].type === "image/png");
      }),
      
    ficheTechnique: yup
    .mixed()
    .required("ce champ est obligatoire")
    .test("type", "ce champ doit être fichier de type pdf", (value) => {
      return (value && value[0].type === "application/pdf") || (value && value[0].type === "application/pdf");
    }),
    brochure: yup
  .mixed()
  .required("ce champ est obligatoire")
  .test("type", "ce champ doit être fichier de type pdf", (value) => {
    return (value && value[0].type === "application/pdf") || (value && value[0].type === "application/pdf");
  }),
  nomproduit:yup
    .string()
    .required("ce champ est obligatoire"),
    subcat:yup
    .string()
    .max(75, "ce champ doit comporter au maximum 50 caractères")
    .required("ce champ est obligatoire"),
    intro:yup
    .string()
    .required("ce champ est obligatoire"),
    desc:yup
    .string()
    .required("ce champ est obligatoire"),
    ft:yup
    .string()
    .required("ce champ est obligatoire"),
    inHomePage:yup
    .string()
    .required("ce champ est obligatoire"),
    ordre:yup
    .number("ce champ doit etre numerique")
    .required("ce champ est obligatoire"),
     
  });

function AddProduct() {
    const history = useHistory();
    const [message, setMessage] = useState('');
    const [uploadPercentage, setUploadPercentage] = useState(0);
    const [subCat, setSubCat]= useState([])
    useEffect(() => {
        Axios.get("/sub-category/get-all")
             .then(res=>setSubCat(res.data))
             .catch(err=>console.log(err.message))
         
    }, [])
    const { register , handleSubmit, errors } = useForm({
        resolver: yupResolver(schema)
      })
      const onSubmit = async (data,e)=>{
        e.preventDefault();
        const newProd = new FormData()
        newProd.append('img', data.img[0]) 
        newProd.append('ficheTechnique',data.ficheTechnique[0])
        newProd.append('brochure',data.brochure[0])
        newProd.append('nomproduit',data.nomproduit)
        newProd.append('intro',data.intro)
        newProd.append('desc',data.desc)
        newProd.append('ft',data.ft)
        newProd.append("inHomePage",data.inHomePage) 
        newProd.append('ordre',data.ordre) 
        newProd.append("uuid",data.subcat)
        try {
          await Axios.post('/product/add-product', newProd, {
           headers: {
           'Content-Type': 'multipart/form-data'
           },
           onUploadProgress: progressEvent => {
          setUploadPercentage(
          parseInt(
           Math.round((progressEvent.loaded * 100) / progressEvent.total)
          )
          );
          //Clear form
          setTimeout(() => setUploadPercentage(0), 1000);
          setTimeout(() => setMessage(''), 1000);
          setTimeout(() => history.push("/gest-Produit"),1100)
          }
          }).then(res=>{
           console.log(res)
          }).catch(err=>{
          console.log(err.message)
           });
          setMessage('File Uploaded');
          } catch (err) {
          if (err.response.status === 500) {
           setMessage('There was a problem with the server');
           } else {
          setMessage(err.response.data.msg);
          }
          }
     
    }

    return (
        <div className="container mt-5">
              
                 <div className="row ">
                     <h1>Ajouter Produit </h1><br/>
                     
                </div> 
                {message ? <Message msg={message} /> : null}
                <form  onSubmit={handleSubmit(onSubmit)}   encType="multipart/form-data"  method="post">
                <div className="form-group ">
                   
                    <label htmlFor="name">Sous Catégorie</label>
                    <select className={`${errors.subcat? `form-control is-invalid`: `form-control` }`} name="subcat" ref={register}  >
                        {
                            subCat.map(item=>(
                                <option key={item.uuid} value={item.uuid}>{item.Category.catName} {item.subcatName}</option>
                            ))
                        }
                         
                    </select>
                    <div className="error ">{errors.subcat?.message}</div>
                </div>
                <div className="form-group">
                    
                    <label htmlFor="name">Nom de Produit</label>
                     <input type="text"  className={`${errors.nomproduit? `form-control is-invalid`: `form-control` }`} id="name" name="nomproduit" aria-describedby="emailHelp"   ref={register}   />
                     <div className="error ">{errors.nomproduit?.message}</div>
                      
                  </div>
                  <div className="form-group">
                    
                         <label htmlFor="name">Intro</label>
                          <input type="text"  className={`${errors.intro? `form-control is-invalid`: `form-control` }`} id="name" name="intro" aria-describedby="emailHelp"   ref={register}  />
                          <div className="error ">{errors.intro?.message}</div>
                </div>  
                <div className="form-group">
                    
                    <label htmlFor="name">Description</label>
                     <textarea  className={`${errors.desc? `form-control is-invalid`: `form-control` }`} id="name" name="desc" aria-describedby="emailHelp"   ref={register}  />
                     <div className="error ">{errors.desc?.message}</div>
           </div>
                <div className="form-group">
                    
                    <label htmlFor="name">Caractéristiques </label>
                     <textarea  className={`${errors.ft? `form-control is-invalid`: `form-control` }`} id="name" name="ft" aria-describedby="emailHelp"  ref={register}   />
                     <div className="error ">{errors.ft?.message}</div>
           </div>  
          
           <div className="form-group">
                    
                    <label htmlFor="name">Visible en Page d'accueil </label>
                     <select  className={`${errors.inHomePage? `form-control is-invalid`: `form-control` }`} id="name" name="inHomePage" aria-describedby="emailHelp"  ref={register}   >
                       <option value={true}>Visible</option>
                       <option value={false}>Caché</option>
                       </select>
                     <div className="error ">{errors.inHomePage?.message}</div>
           </div> 
           <div className="form-group">
                    
                    <label htmlFor="name">Ordre</label>
                     <input type="text" className={`${errors.ordre? `form-control is-invalid`: `form-control` }`} id="name" name="ordre" aria-describedby="emailHelp"   ref={register}  />
                     <div className="error ">{errors.ordre?.message}</div>
                  </div>
                <div className='form-group mb-4'>
                <label>Image </label>        
           <input
            type='file'
            className={`${errors.img? `form-control is-invalid`: `form-control` }`}
            id='customFile' 
             name="img" 
             ref={register}  
          />
            <div className="error ">{errors.img?.message}</div>
          </div>
          
          <div className='form-group mb-4'>
          <label>fiche Technique</label>
           <input
            type='file'
            className={`${errors.ficheTechnique? `form-control is-invalid`: `form-control` }`}
            id='customFile'
            name="ficheTechnique" ref={register} />
            
            <div className="error ">{errors.ficheTechnique?.message}</div>
          </div>
          <div className='form-group mb-4'>
              <label>Brochure</label>
           <input
            type='file'
            className={`${errors.brochure? `form-control is-invalid`: `form-control` }`}
            id='customFile'
            name="brochure" 
             ref={register} />
           <div className="error ">{errors.brochure?.message}</div>
          </div>
          
        
         <Progress percentage={uploadPercentage} />     
         <br/>
         <button type="submit" className="btn" style={{backgroundColor:"#FF2B3A",color:'#fff'}}  >Ajouter</button>

                </form>
        </div>
    )
}

export default AddProduct

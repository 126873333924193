import React,{useCallback,useState,useEffect,useRef} from 'react';
import styled from 'styled-components';
import Burger from './Burger';
import { HashLink   } from 'react-router-hash-link';
import {NavbarBrand} from 'reactstrap';

const Nav = styled.nav`
width: 100%;
height: 100px; 
padding: 0 20px;
display: flex;
justify-content: space-between;

 
.logo {
  padding: 15px 0;
}
`

const Navbar = () => {
  const navRef = useRef(null); 
  function logit() {
    if (window.pageYOffset > 100) { 
     navRef.current.classList.add("test-nav-scroll")
    }
    else { 
      navRef.current.classList.remove("test-nav-scroll")
    }
   
  }
 function blurrBody(){
   if(!navRef.current.classList.contains("wrapper-navbar") ){
    document.body.classList.add("blur-body")
    navRef.current.classList.add("wrapper-navbar")
   }else{
    document.body.classList.remove("blur-body")
    
   }
 } 
  useEffect(() => {
    function watchScroll() {
      window.addEventListener("scroll", logit);
    } 
    watchScroll(); 
    return () => {
      window.removeEventListener("scroll", logit); 
    };
  },[]);
  
  const [open, setOpen] = useState(false);
  
  const callback = useCallback((open) => {
    setOpen(open);
    blurrBody();
  }, []); 
  return (
    
   <> <Nav className={`navigation-wrap bg-white  sticky-top ${open ? `wrapper-navbar` : ``}`} id="nav"  ref={navRef}>
      <div className="logo ">
      <HashLink   to={{pathname:"/"}} style={{color:"#000000"}} > 
       <NavbarBrand   rel="noreferrer"  
      ><img src="../../../../imgs/logo.png" alt="logo"/>
      </NavbarBrand>	
      </HashLink>
      </div>
      <Burger parentCallback={callback}  navRef={navRef}  />
      
    </Nav>
    <hr className="new4"/>
    </>
  )
}

export default Navbar
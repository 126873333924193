import React from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Axios from 'axios';
import { useHistory } from "react-router-dom";
const schema = yup.object().shape({
    email:yup
    .string()
    .required("ce champ est obligatoire"),
    phoneNumber:yup
    .string() 
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(8, "ce champ doit comporter au maximum 8 chiffres")
    .max(8, "ce champ doit comporter au minimum 8 chiffres")
    .required("ce champ est obligatoire"),
    poste:yup
    .string() 
    .required("ce champ est obligatoire"), 
  });
export default function FormTeam({preloadedValues, match}) {
    const history = useHistory();
    const { register, handleSubmit, errors } = useForm({
        defaultValues: preloadedValues,
        resolver: yupResolver(schema)
      });
      
      const onSubmit =   (data,e) => {
        e.preventDefault();
        const updatedTeam = new FormData()
        updatedTeam.append("poste",data.poste)
        updatedTeam.append("email",data.email)
        updatedTeam.append("phoneNumber",data.phoneNumber) 
        Axios.put(`/team/edit/${match}`,updatedTeam)
        .then((res)=>{
                console.log(res.data);
                history.push("/gest-equipe");
            })
        .catch(err=>console.log(err.message))
        };
    return (
        <div className="container mt-5">
        <div className="row row-a">
            <div className="col-md-8 col-lg-8">
                <h1>Modifier Equipe </h1><br/>
             <form   onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data"  >
              
                <div className="form-group">
                    <label htmlFor="name">Poste</label>
                     <input  readOnly type="text" className={`${errors.poste? `form-control is-invalid`: `form-control` }`} id="name" name="poste" ref={register}  />
                     <div className="error ">{errors.poste?.message}</div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="name">Email</label>
                     <input type="email" className={`${errors.email? `form-control is-invalid`: `form-control` }`} id="name" name="email"  ref={register}  />
                     <div className="error ">{errors.email?.message}</div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="name">Numero Tel</label>
                     <input type="text" className={`${errors.phoneNumber? `form-control is-invalid`: `form-control` }`} id="name" name="phoneNumber"  ref={register}  />
                     <div className="error ">{errors.phoneNumber?.message}</div>
                  </div> 
   <button type="submit" className="btn  mt-5" style={{backgroundColor:"#FF2B3A",color:'#fff'}} >Modifier</button>
           </form>
           </div>
           </div>
           </div>
    )
}

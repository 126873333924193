import React,{useReducer,useEffect}  from 'react'
import {faThumbsUp,faCalendar} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Loading from './Loading';
 
 
const initialState={
    loading:true,
    error:'',
    post:{}
}
const reducer=(state,action)=>{
    switch(action.type){
        case "fetch success":
            return {
                loading:false,
                post: action.payload,
                error:''
            }
       case "fetch error":
           return {
               loading:false,
               post: {},
               error: "something went wrong "
           }
        default :
          return state
    }
  }
function FacebookFeeds() {
  
    const [state,dispatch]= useReducer(reducer , initialState)
     useEffect(() => {
        Axios.get("https://graph.facebook.com/v10.0/me?fields=posts.limit(3)%7Bfull_picture%2Cmessage%2Ccreated_time%2Cpermalink_url%2Clikes.summary(true)%7D&access_token=EAAKol99ylYABAM6iIEXybZAdZAiJ4PiWI2O1EMLdP7RoMCZA01belyZBhAK72TkViKcB7qctClwIfcoshmOiUiFSKOdJ9YHOnUXQQMR10o99nnhi2AeKh19ntlrA0PTl9pQJgZA34Fe3qAJLPc5RansVO3wTgKWg5XbjdXXYVBFMSwsuC3nU7")
        .then((res) => {
            dispatch({ type: "fetch success", payload: res.data });
          })
          .catch((error) => {
            dispatch({ type: "fetch error", payload: error.message });
          });
         
    }, [])
    return (
        
        <div className="container-fluid mt-5 ">
        <div className=" box-fb-container mx-md-5 mx-lg-5"> 
      <div className="heading">
              <h3 className="head font"><span className="before-title mr-3"></span>Actualité</h3>
              
                
            </div>
  <Carousel
  additionalTransfrom={0}
  arrows={true} 
  centerMode={false}
     
  draggable
  focusOnSelect={false}
    
   keyBoardControl
  minimumTouchDrag={80}
  renderButtonGroupOutside={false}
  renderDotsOutside={false}
  responsive={{
    desktop: {
      breakpoint: {
        max: 3000,
        min: 1024
      },
      items: 3,
       
    },
    mobile: {
      breakpoint: {
        max: 464,
        min: 180
      },
      items: 1,
      
    },
    tablet: {
      breakpoint: {
        max: 1024,
        min: 464
      },
      items: 1,
       
    }
  }}
  showDots={false}
  sliderClass="my-3 " 
  swipeable
> 
 {state.loading ? <Loading/> : state.post.posts.data.map((item,key)=>(
           <div className="box-fb mx-2 mb-5" key={key}>
              <a 
               href={item.permalink_url}
               rel="noreferrer"
               target="_blank"
               style={{color:"#FF2B3A"}}
               > 
               <div className="blog-grid" id="zoomIn">
                
                <figure  >
                  <img
                    src={item.full_picture}
                    className="img-fluid-fb"
                    alt="facebook posts"
                  />{" "}
                </figure>
                </div>
                <div className="over-layer">
                    <div className="box-content">
                        <h3 className="title" >{item.message.substring(0, 130)}... <br/>
                        <a 
               href={item.permalink_url}
               rel="noreferrer"
               target="_blank"
               style={{color:"white", whiteSpace:"pre-line"}}
               >voir plus</a>
                        </h3>
                       
                    </div>
                    <ul className="links d-flex mt-5 pt-5">
                    <li style={{display:"flex"}} className="p-2 date_fb"><a><FontAwesomeIcon icon={faCalendar} /></a><span className="my-auto date"> {new Date(item.created_time).toISOString().slice(0, 10)}</span></li>
                    <li className="ml-auto p-2 likes"><a><FontAwesomeIcon icon={faThumbsUp} />{item.likes.summary.total_count}</a></li>
                     </ul>
                </div>
                </a>
            </div>))}
            
             
             
       
    </Carousel>
     
    </div> 

    </div>
             
             
    )
}

export default FacebookFeeds

import React,{useState, useEffect} from 'react'
import Axios from 'axios';
import FormCarousel from './FormCarousel';
import Loading from '../../Loading';

function EditCarousel({match}) {
   
        const [carousel,setCarousel]=useState(null)
    
        useEffect(() => {
        Axios.get(`/carousel/get-one/${match.params.uuid}`)
                    .then((result)=>setCarousel(result.data[0]))
                    .catch((error)=>console.log(error.message));
                }, [match.params.uuid])
                return  carousel ? <FormCarousel preloadedValues={carousel} match={match.params.uuid}/> :  <Loading/>
    
}

export default EditCarousel

 import React, { useState, useEffect } from "react";
import Axios from "axios";
import { HashLink as Link } from "react-router-hash-link";
import Loading from "../components/Loading";
function SearchResult({match}) {
    const [result, setResult] = useState([]);
    const [loading, setLoading] = useState(true)
 
     
  useEffect(() => {
    Axios.get(`/frontend/search/${match.params.word}`)
      .then((res) => {
        setResult(res.data); 
        setLoading(false);
        
      })
      .catch((err) => console.log(err.message));
  }, [match.params.word]);
    return (
       
        <div className="container-fluid" >
          <div className="row mx-lg-5 mx-md-5 mx-auto">
           {loading ? <Loading/> : result.length !== 0  ?  result.map((val,key)=>(
           <div className="col-md-6 col-lg-4 col-12" key={key}>
        <div className="card-result">
        <div className="card-image">
          <Link to={`/${val.Subcategory.Category.catName}/${val.Subcategory.subcatName}/${val.productName}/${val.uuid}`}>
            <img src={`../../imgs/${val.productimgPath}`} alt="img.jpg" />
          </Link>
        </div>
        
        <div className="card-description">
          <h3>{val.productName}</h3>
         
          <Link to={`/${val.Subcategory.Category.catName}/${val.Subcategory.subcatName}/${val.productName}/${val.uuid}`}>Voir Plus</Link>
        </div>
      </div>
      
      </div>)) : (<div className="col-12 col-md-12 col-lg-12">
            <h1 className="text-center my-5">
                {" "}
                  Aucun Résultat Trouvé Pour "{match.params.word}"{" "}
                 </h1>
          </div>)
      }
       
       
      </div>
      </div>
    )
}

export default SearchResult

import React from 'react'
import { HashLink as Link} from 'react-router-hash-link'

function NoMatch() {
    return (
        <div className="container container-nm">
            <div className="row row-a">
                <div className="col-md-6">
                    <h1>OOPS !</h1>
                    <p className="text-justify text-capitalize">
                    nous n’arrivons pas à trouver la page que vous cherchez <br/>
                     voici quelques liens utiles à la place :
                    </p>
                    <ul className="ml-5"> 
                  
                         
                        <li><Link smooth to={{pathname:"/entreprise",hash:"toppage"}}>Entreprise</Link></li>
                        <li><Link smooth to={{pathname:"/produit",hash:"toppage"}}>Produits</Link></li>
                        <li><Link smooth to={{pathname:"/service",hash:"toppage"}}>Service</Link></li>
                        <li><Link smooth to={{pathname:"/contact",hash:"toppage"}}>Contactez-Nous</Link></li> 
                        
                    </ul>
                </div>
                <div className="col-md-6">
                    <div>
                        <img src="../../imgs/logo-gst-01.svg" style={{width:"50%",height:"50%"}}/>
                    </div>
                    <br/>
                    <h1 className="text-capitalize">
                     Partenaire Des Topographes
                    </h1>
                </div>
            </div>
        </div>
    )
}

export default NoMatch

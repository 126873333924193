import React,{useState,useEffect} from 'react';
import Axios from 'axios';
import Message from '../Message';
import Progress from "../Progress";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
 const schema = yup.object().shape({
    subcatimgPath: yup
      .mixed()
      .required("ce champ est obligatoire")
      .test("type", "ce champ doit être image de type jpeg or png", (value) => {
        return (value && value[0].type === "image/jpeg") || (value && value[0].type === "image/png");
      }),
      subcatbannerimgPath: yup
    .mixed()
    .required("ce champ est obligatoire")
    .test("type", "ce champ doit être image de type jpeg or png", (value) => {
      return (value && value[0].type === "image/jpeg") || (value && value[0].type === "image/png");
    }),
    cat:yup
    .string()
    .required("ce champ est obligatoire"),
    ordre:yup
    .number("ce champ doit etre numerique")
    .required("ce champ est obligatoire"),
    subcatName:yup
    .string()
    .max(50, "ce champ doit comporter au maximum 50 caractères")
    .required("ce champ est obligatoire"),
    introsubCat:yup
    .string()
    .max(200, "ce champ doit comporter au maximum 200 caractères")
    .required("ce champ est obligatoire"),
     
  });

function AddSubcategory() {
    const history = useHistory();
    const [message, setMessage] = useState('');
    const [uploadPercentage, setUploadPercentage] = useState(0);
    const [subCat,setSubCat]=useState([])
   useEffect(()=>{
       Axios.get( "/category/get-all")
            .then(res=>{
                setSubCat(res.data)
                })
            .catch(error=>{
                 console.log(error.message)
            })
   },[])
   const { register , handleSubmit, errors } = useForm({
    resolver: yupResolver(schema)
  }) 
  const onSubmit = async (data,e)=>{
    e.preventDefault();
    const newCat = new FormData()
    newCat.append("subcatbannerimgPath",data.subcatbannerimgPath[0])
    newCat.append('subcatimgPath', data.subcatimgPath[0])
    newCat.append('cat',data.cat)
    newCat.append('subcatName',data.subcatName)
    newCat.append('introsubCat',data.introsubCat) 
    newCat.append('ordre',data.ordre) 
     try {
      await Axios.post('/sub-category/add-sub-category', newCat, {
       headers: {
       'Content-Type': 'multipart/form-data'
       },
       onUploadProgress: progressEvent => {
      setUploadPercentage(
      parseInt(
       Math.round((progressEvent.loaded * 100) / progressEvent.total)
      )
      );
      //Clear form
      setTimeout(() => setUploadPercentage(0), 1000);
      setTimeout(() => setMessage(''), 1000);
      setTimeout(() => history.push("/gest-Sub-Category"),1100)
      }
      }).then(res=>{
       console.log(res)
      }).catch(err=>{
      console.log(err.message)
       });
      setMessage('File Uploaded');
      } catch (err) {
      if (err.response.status === 500) {
       setMessage('There was a problem with the server');
       } else {
      setMessage(err.response.data.msg);
      }
      }
 
}
    return (
        <div className="container mt-5">
              
                 <div className="row col-md-6 col-lg-6">
                     <h1>Ajouter Sous Catégorie </h1><br/>
                     {message ? <Message msg={message} /> : null}
                </div> 
                <form onSubmit={handleSubmit(onSubmit)}  encType="multipart/form-data"  method="post">
                <div className="form-group ">
                   
                    <label htmlFor="name">Nom de Catégorie</label>
                    <select className={`${errors.cat? `form-control is-invalid`: `form-control` }`} name="cat"  ref={register}>
                        {
                            subCat.map(item => (
                                <option key={item.uuid} value={item.uuid} >{item.catName}</option>
                            ))
                        }
                        
                    </select>
                    <div className="error ">{errors.cat?.message}</div>
                </div>
                <div className="form-group">
                    
                    <label htmlFor="name">Nom</label>
                     <input type="text" className={`${errors.subcatName? `form-control is-invalid`: `form-control` }`} id="name" name="subcatName" aria-describedby="emailHelp"   ref={register}  />
                     
                     <div className="error ">{errors.subcatName?.message}</div>
                  </div>
                  <div className="form-group">
                    
                         <label htmlFor="name">Intro</label>
                          <textarea className={`${errors.introsubCat? `form-control is-invalid`: `form-control` }`} id="name" name="introsubCat" aria-describedby="emailHelp"  ref={register}   />
                          <div className="error ">{errors.introsubCat?.message}</div>
                </div> 
                <div className="form-group">
                    
                    <label htmlFor="name">Ordre</label>
                     <input type="text" className={`${errors.ordre? `form-control is-invalid`: `form-control` }`} id="name" name="ordre" aria-describedby="emailHelp"   ref={register}  />
                     <div className="error ">{errors.ordre?.message}</div>
                  </div>
                <div className='form-group mb-4'>
                <label>Image</label>      
           <input
            type='file'
            className={`${errors.subcatimgPath? `form-control is-invalid`: `form-control` }`}
            id='customFile' 
             name="subcatimgPath" 
             ref={register}
          />
            <div className="error ">{errors.subcatbannerimgPath?.message}</div>
          </div>
         
        <div className='form-group mb-4'>
        <label>Image De Couverture</label>
           <input
            type='file'
            className={`${errors.subcatbannerimgPath? `form-control is-invalid`: `form-control` }`}
            id='customFile'
            name="subcatbannerimgPath" 
            ref={register}
             
          />
           <div className="error ">{errors.subcatbannerimgPath?.message}</div>
          </div>
          
        
         <Progress percentage={uploadPercentage} />     
         <br/>
         <button type="submit" className="btn" style={{backgroundColor:"#FF2B3A",color:'#fff'}}  >Ajouter</button>

                </form>
        </div>
    )
}

export default AddSubcategory

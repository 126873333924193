import React,{useState,useEffect} from 'react';
import Axios from "axios";
import { HashLink as Link } from 'react-router-hash-link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faArrowRight} from "@fortawesome/free-solid-svg-icons"; 

function ProductsHome() {
    const [block,setBlock]=useState([]);
    useEffect(() => {
        Axios.get("/home/get-all")
             .then(response =>setBlock(response.data))
             .catch(error => console.log(error.message))
      }, [])
    return (
        <div className="container-fluid">
        <div className="row mx-md-5 mx-lg-5 mt-3 mt-md-5 mt-lg-5">

        
          
          {block.map(item=>(
          <div className="col-md-4 col-lg-4 mt-2 mb-5 home-block" key={item.uuid}>
          <Link style={{color:"#000000", margin:"0"}} to="/produit"> 
          <img src={`../../imgs/${item.imgPath}`} alt="produits"  /></Link>
              <hr className="produit"/>
              <Link  style={{color:"#000000"}} to={`${item.link}`}>
                  <h3 className="font">{item.name}</h3>
                    </Link> 
              <p className="text-left gst-intro">{item.intro} </p>
              <Link className="plus-link" style={{color:"#000000"}} to={`${item.link}`} >En Savoir Plus <FontAwesomeIcon icon={faArrowRight} style={{color:"#FF2B3A"}}/> </Link>
              <hr className="sparation"/>
          </div>
            
          ))}
         
           
          
      
        </div> 
    </div>
    )
}

export default ProductsHome

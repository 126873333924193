import React ,{useState,useEffect} from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Axios from "axios"

function GestClient() {
    const [modal, setModal] = useState(false);
    const [uuid, setUuid]= useState("")
  
    const toggle = (uuid) => {
     setModal(!modal);
     setUuid(uuid);  }
     const closeModal=()=>{
       setModal(!modal);
     }
     const[clients, setClients]=useState([])
     useEffect(() => {
         Axios.get("/client/get-all")
              .then(res=>setClients(res.data))
              .catch(err=>console.log(err.message))
     }, [])
     const deleteProduct=async(uuid)=>{
        await  Axios.delete(`/client/delete/${uuid}`);
        setClients(prevsubPic=>prevsubPic.filter(val=> val.uuid !== uuid));
        setModal(false); 
     }
    return (
        <div className="container mt-5">
        <div className="col-lg-12 col-md-12 d-flex justify-content-between" style={{backgroundColor:'#c9c9c9'}}>
         <h1 className="justify-content-start" style={{color:'#000'}}>Ajouter Images Clients</h1>
          <Link to="/gest-ref/add-ref" style={{color:"inherit", marginTop:"1%"}}><button className="btn   justify-content-end" style={{backgroundColor:"#FF2B3A",color:"#fff"}}>Ajouter </button></Link>
         </div>
             <div className="col-lg-12 col-md-12 ">
             <table class="table table-hover">
  <thead>
    <tr>
      
      <th scope="col">Image</th>
      <th scope="col">Actions</th>
    </tr>
  </thead>
  <tbody>
    {
     clients.map((item,key)=>(<tr key={key}>
     
      <td style={{width:"30%"}}><img src={`../../imgs/${item.imgPath}`} style={{width:"55px", height:"50px"}} alt="" /></td>
      <td><button className="btn btn-danger" onClick={(e)=>toggle(item.uuid)} >Supprimer <FontAwesomeIcon icon={faTrashAlt} style={{color:"#FFFFFF"}}/> </button> </td>
       
    </tr>))}
    <Modal isOpen={modal} uuid={uuid} toggle={(e)=>closeModal}>
        <ModalHeader toggle={closeModal}>Vous êtes sûr ? </ModalHeader>
        <ModalBody>
        
Le Produit Sera Supprimée Définitivement
        </ModalBody>
        <ModalFooter>
          <Button color="btn btn-danger" onClick={(e)=>deleteProduct(uuid)}>Supprimer <FontAwesomeIcon icon={faTrashAlt} style={{color:"#FFFFFF"}}/> </Button>
          <Button color="secondary" onClick={(e)=>closeModal()}>Annuler</Button>
        </ModalFooter>
      </Modal>
  </tbody>
</table>
             </div>
             </div>
    )
}

export default GestClient

import React from 'react'
import { useAuth0 } from '@auth0/auth0-react';

const LoginButton = () => {
    const { loginWithRedirect } = useAuth0();

    return (
        <div className="container mt-5"> 
         
           <button className="btn btn-primary"  onClick={() => loginWithRedirect()} 
                   style={{marginTop:"30%",marginLeft:"50%",marginBottom:"90%"}}>
                   Log In
           </button> 
           </div>
         
    )
}

export default LoginButton

import React from 'react'
import CarouselHomePage from './CarouselHomePage'
import Company from "./Company" 
import CarouselClients from "./CarouselClients"
import ProductCarousel from './ProductCarousel'
import FacebookFeeds from './FacebookFeeds'
import ProductsHome from './ProductsHome'
import {ErrorBoundary} from "../components/ErrorBoundary";
 function HomePage() {
    return (
        <div id="toppage">
          <CarouselHomePage/>  
          <Company/> 
          <ProductsHome/>
          <ProductCarousel/>
          <ErrorBoundary>
          <FacebookFeeds/>
          </ErrorBoundary>
           <CarouselClients/>
        </div>
    )
}

export default HomePage

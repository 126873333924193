import React,{useState} from 'react';
import Axios from 'axios';
import Message from '../Message';
import Progress from "../Progress";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
const schema = yup.object().shape({
    imgPath: yup
      .mixed()
      .required("ce champ est obligatoire")
      .test("type", "ce champ doit être image de type jpeg", (value) => {
        return (value && value[0].type === "image/jpeg") || (value && value[0].type === "image/png");;
      }),
    page:yup
    .string()
    .required("ce champ est obligatoire")
    .test("type", "ce champ doit être unique", (value) => {
      return (value && value !== "produit") && (value && value !== "service") && (value && value !== "entreprise");
    }),
    
  });


function AddBanner() {
    const history = useHistory();
     const [message, setMessage] = useState('');
     const [uploadPercentage, setUploadPercentage] = useState(0);
     const { register , handleSubmit, errors } = useForm({
        resolver: yupResolver(schema)
      }) 
      const onSubmit = async (data,e)=>{
        e.preventDefault();
        const newBanner = new FormData()
        newBanner.append('imgPath', data.imgPath[0])
        newBanner.append('page',data.page)
        
        console.log(data)
        try {
          await Axios.post('/banner/add-banner', newBanner, {
           headers: {
           'Content-Type': 'multipart/form-data'
           },
           onUploadProgress: progressEvent => {
          setUploadPercentage(
          parseInt(
           Math.round((progressEvent.loaded * 100) / progressEvent.total)
          )
          );
          //Clear form
          setTimeout(() => setUploadPercentage(0), 1000);
          setTimeout(() => setMessage(''), 1000);
          setTimeout(() => history.push("/gest-banner"),1100)
          }
          }).then(res=>{
           console.log(res)
          }).catch(err=>{
          console.log(err.message)
           });
          setMessage('File Uploaded');
          } catch (err) {
          if (err.response.status === 500) {
           setMessage('There was a problem with the server');
           } else {
          setMessage(err.response.data.msg);
          }
         }
     
    }
    return (
        <div className="container mt-5">
              
                 <div className="col-md-8 col-lg-8">
                 <h1>Ajouter Image De Couverture </h1><br/>
                 {message ? <Message msg={message} /> : null}
                 <form onSubmit={handleSubmit(onSubmit)}  encType="multipart/form-data"  method="post">
                 <div className='form-group mb-4 mt3'>
                    <label>Page</label>
                    <select className={`${errors.page? `form-control is-invalid`: `form-control` }`} name="page" ref={register}>
                    <option value="entreprise">Page Entreprise</option>
                    <option value="produit">Page Produits</option>
                    <option value="service">Page Services</option>
                    </select>
                    <div className="error ">{errors.page?.message}</div>
                    </div>
                    <div className='form-group mb-4 mt3'>
                    <label>Image</label>      
           <input
            type='file'
            className={`${errors.imgPath? `form-control is-invalid`: `form-control` }`}
            id='customFile' 
             name="imgPath" 
             ref={register}

          />
          <div className="error ">{errors.imgPath?.message}</div>
          </div>
          <Progress percentage={uploadPercentage} />     
         <br/>
         <button type="submit" className="btn" style={{backgroundColor:"#FF2B3A",color:'#fff'}}  >Ajouter</button>
                </form>   
                 </div>
        </div>
    )
}

export default AddBanner

import React,{useState,useEffect} from 'react';
import Axios from 'axios';
import Message from '../Message';
import Progress from "../Progress";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
const schema = yup.object().shape({
    imgPath: yup
      .mixed()
      .required("ce champ est obligatoire")
      .test("type", "ce champ doit être image de type jpeg or png or gif", (value) => {
        return (value && value[0].type === "image/jpeg") || (value && value[0].type === "image/png") || (value && value[0].type === "image/gif");
      }),
    name:yup
    .string()
    .required("ce champ est obligatoire"),
    intro:yup
    .string()
    .required("ce champ est obligatoire"),
    link:yup
    .string()
    .required("ce champ est obligatoire"),
    ordre:yup
    .string() 
    .required("ce champ est obligatoire"),
  });

function AddBlock() {
    const history = useHistory();
    const [message, setMessage] = useState('');
    const [uploadPercentage, setUploadPercentage] = useState(0);
    const [pages, setPages] = useState([]);
    useEffect(() => {
       Axios.get("/home/get-all-categories")
       .then(res=>{
        setPages(res.data)
        })
    .catch(error=>{
         console.log(error.message)
    })
    }, [])
 
 const { register , handleSubmit, errors } = useForm({
   resolver: yupResolver(schema)
 }) 
 const onSubmit = async (data,e)=>{
    e.preventDefault();
    const newBlock = new FormData()
    newBlock.append('imgPath', data.imgPath[0])
    newBlock.append('name',data.name)
    newBlock.append('intro',data.intro)
    newBlock.append("link",data.link)
    newBlock.append("ordre",data.ordre)
    try {
      await Axios.post('/home/add-block', newBlock, {
       headers: {
       'Content-Type': 'multipart/form-data'
       },
       onUploadProgress: progressEvent => {
      setUploadPercentage(
      parseInt(
       Math.round((progressEvent.loaded * 100) / progressEvent.total)
      )
      );
      //Clear form
      setTimeout(() => setUploadPercentage(0), 1000);
      setTimeout(() => setMessage(''), 1000);
      setTimeout(() => history.push("/gest-home"),1100)
      }
      }).then(res=>{
       console.log(res)
      }).catch(err=>{
      console.log(err.message)
       });
      setMessage('File Uploaded');
      } catch (err) {
      if (err.response.status === 500) {
       setMessage('There was a problem with the server');
       } else {
      setMessage(err.response.data.msg);
      }
      }
 
}
    return (
        <div className="container mt-5">
              
        <div className="col-md-8 col-lg-8">
            <h1>Ajouter Block </h1><br/>
            {message ? <Message msg={message} /> : null}
        <form onSubmit={handleSubmit(onSubmit)}  encType="multipart/form-data"  method="post">
            <div className="form-group">
           
                <label htmlFor="name">Titre</label>
                 <input type="text" className={`${errors.name? `form-control is-invalid`: `form-control` }`} id="name" name="name" aria-describedby="emailHelp" ref={register}  />
                 <div className="error ">{errors.name?.message}</div>
              </div>
              <div className="form-group">
           
                <label htmlFor="name">Intro</label>
                 <textarea type="text" className={`${errors.intro? `form-control is-invalid`: `form-control` }`} id="name" name="intro" aria-describedby="emailHelp" ref={register}  />
                 <div className="error ">{errors.intro?.message}</div>
              </div>
              <div className="form-group">
           
                <label htmlFor="name">Ordre</label>
                 <input type="text" className={`${errors.ordre? `form-control is-invalid`: `form-control` }`} id="name" name="ordre" aria-describedby="emailHelp" ref={register}  />
                 <div className="error ">{errors.ordre?.message}</div>
              </div>
              <div className="form-group">
              <label htmlFor="name">Lien</label>
              <select className={`${errors.link? `form-control is-invalid`: `form-control` }`} id="name" name="link" aria-describedby="emailHelp" ref={register}>
              <optgroup label="les pages Statiques">
              <option value="/entreprise">Page Entreprise</option>
                    <option value="/produit">Page Produits</option>
                    <option value="/service">Page Services</option>
                    <option value="/contact">Page Contactez-nous</option>
              </optgroup>
              <optgroup label="les pages de Catégories">
                {
                  pages.map(item=>(
                    <option key={item.uuid} value={`/${item.catName}/${item.uuid}`}>page {item.catName}</option>
                      ))
                }
              </optgroup>
              <optgroup label="les pages de Sous Catégories">
              {
                  pages.map(item=>(<>
                   {item.Subcategories.map(val=>(<option key={val.uuid} value={`/${item.catName}/${val.subcatName}/${val.uuid}`}  >page {item.catName} {val.subcatName}</option>))}</>
                      ))
                }
              </optgroup> 
              <optgroup label="les pages de Produit">
              {
                  pages.map(item=>(<>
                   {item.Subcategories.map(value=>(<>
                  {value.Products.map(data=>(<option key={data.uuid} value={`/${item.catName}/${value.subcatName}/${data.productName}/${data.uuid}`}  >page {data.productName} </option> ))}
                   </>))}</>
                      ))
                }
              </optgroup>
              </select>
              <div className="error ">{errors.link?.message}</div>
              </div>
              
              <div className='form-group mb-4'>
             <label>Image</label>
  <input
   type='file'
   className={`${errors.imgPath? `form-control is-invalid`: `form-control` }`}
   id='customFile'
   ref={register}
   name="imgPath"
 />
  <div className="error ">{errors.imgPath?.message}</div>
 </div> 
 
 

<Progress percentage={uploadPercentage} />
           
           <br/>
           <button type="submit" className="btn" style={{backgroundColor:"#FF2B3A",color:'#fff'}}  >Ajouter</button>
       </form>
       </div>
       </div>
    )
}

export default AddBlock

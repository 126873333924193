import React from 'react' 
function Loading() {
    return (
      <div className="loading-page-container mt-3">
      <div className="progress">
        <div className="inner text-center">
           
          <img src="../../imgs/logo-gst-01.svg" alt="" /> 
          <div className="water"></div>
        </div>
      </div>
    </div>
    )
}

export default Loading

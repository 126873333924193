import React,{useState} from 'react'
import Axios from "axios";
import Message from '../Message';
import Progress from '../Progress';
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";

 

function AddClient() {
 const history = useHistory();
 const [message, setMessage] = useState('');
 const [uploadPercentage, setUploadPercentage] = useState(0);
 const [selectedImages, setSelectedImages ] = useState([]);
 const [selectedImagesName, setSelectedImagesName ] = useState("images");
 const imagesHandler=e=>{
       setSelectedImages(e.target.files)
       setSelectedImagesName(e.target.files.name)
     }
     const { handleSubmit } = useForm({})
const store= async ()=>{
         
        const newImages =  new FormData();
         
        if (selectedImages.length !== 1) {
            for (let i = 0; i < selectedImages.length; i++) {
                newImages.append("imgPaths", selectedImages[i])
               }
        } else {
            newImages.append("imgPaths",selectedImages[0])
        }
       
        try {
           await Axios.post("/client/add-picture",newImages, {
            headers: {
              'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: progressEvent => {
              setUploadPercentage(
                parseInt(
                  Math.round((progressEvent.loaded * 100) / progressEvent.total)
                )
              );
    
              // Clear form
              setTimeout(() => setUploadPercentage(0), 2000);
              setTimeout(() => setMessage(''), 1000);
              setTimeout(() => setSelectedImages([]),1000);
              setTimeout(() => setSelectedImagesName('images'),1000)
              setTimeout(() => history.push("/gest-ref"),2050)
               
            }
          });
    
          setMessage('Images Uploaded');
        } catch (error) {
          
        }
         
    }
    return (
        <div className="container">
            <div className="row">
             
                <h1>Ajouter Images Clients </h1><br/>
                {message ? <Message msg={message} /> : null}
            </div>
            <form onSubmit={handleSubmit(store)}  encType="multipart/form-data"  method="post">
              
            <div className='custom-file   mb-3'>
               <input
                 type='file'
                 
                 className="custom-file-input"
                 id='customFile'
                 multiple
                 accept="image/png,image/jpeg"
                 onChange={imagesHandler}
                 name="imgPath"
                  
                 />
      <label className='custom-file-label' htmlFor='customFile'>
        {selectedImagesName}
      </label>
      
     
    </div> 
    <Progress percentage={uploadPercentage} />
    <button type="submit" className="btn mt-3" style={{backgroundColor:"#FF2B3A",color:'#fff'}}  >Ajouter</button>
            </form>
            </div>
            
    )
}

export default AddClient

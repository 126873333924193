import React,{useState, useEffect} from 'react'
import Axios from 'axios';
 import Loading from '../../Loading';
 import FormBanner from "../Banner/FormBanner"

function EditBanner({match}) {
    const [banner,setBanner]=useState(null)
    
    useEffect(() => {
    Axios.get(`/banner/get-one/${match.params.uuid}`)
                .then((result)=>setBanner(result.data[0]))
                .catch((error)=>console.log(error.message));
            }, [match.params.uuid])
   return banner ? <FormBanner preloadedValues={banner} match={match.params.uuid}/> : <Loading/>
}

export default EditBanner

import React,{useState,useEffect} from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Axios from 'axios';
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faFilePdf} from "@fortawesome/free-solid-svg-icons";
const schema = yup.object().shape({
    productName:yup
    .string()
    .required("ce champ est obligatoire"),
    SubcategoryUuid :yup
    .string()
    .max(75, "ce champ doit comporter au maximum 50 caractères")
    .required("ce champ est obligatoire"),
    productIntro:yup
    .string()
    .required("ce champ est obligatoire"),
    productDescription:yup
    .string()
    .required("ce champ est obligatoire"),
    characteristics:yup
    .string()
    .required("ce champ est obligatoire"),
    inHomePage:yup
    .string()
    .required("ce champ est obligatoire"), 
    ordre:yup
    .number("ce champ doit etre numerique")
    .required("ce champ est obligatoire"),
  });

export default function FormProduct({preloadedValues, match}) {
    const history = useHistory();
    const { register, handleSubmit, errors } = useForm({
        defaultValues: preloadedValues,
        resolver: yupResolver(schema)
      });
      
      const onSubmit =  async (data,e) => {
        e.preventDefault();
        const updatedProduct = new FormData()
        updatedProduct.append('img', data.img[0])
        updatedProduct.append('ficheT', data.ficheT[0])
        updatedProduct.append("broch",data.broch[0])
        updatedProduct.append("SubcategoryUuid",data.SubcategoryUuid)
        updatedProduct.append("productName",data.productName)
        updatedProduct.append("productIntro",data.productIntro)
        updatedProduct.append("productDescription",data.productDescription)
        updatedProduct.append("characteristics",data.characteristics)
        updatedProduct.append('ordre',data.ordre)
        updatedProduct.append("inHomePage",data.inHomePage) 
         await Axios.put(`/product/edit/${match}`,updatedProduct)
        .then((res)=>{
                console.log(res.data);
                history.push("/gest-Produit");
            })
        .catch(err=>console.log(err.message))
        };
        const [subCat, setSubCat]=useState([])
   useEffect(()=>{
       Axios.get( "/sub-category/get-all")
            .then(res=>{
                setSubCat(res.data)
                })
            .catch(error=>{
                 console.log(error.message)
            })
   },[])
   const [product, setProduct]= useState({
    SubcategoryUuid  : "",
   })
    useEffect(() => {
        Axios.get(`/product/get-one/${match}`)
                    .then((result)=>setProduct(result.data[0]))
                    .catch((error)=>console.log(error.message));
                }, [match])
                const onChange = (e) => {
                    setProduct({...product, [e.target.name]: e.target.value});
                  }
                 
    return (
        <div className="container mt-5">
              
                 <div className="row ">
                     <h1>Modifier Produit </h1>
                      
                </div> 
                <form  onSubmit={handleSubmit(onSubmit)}   encType="multipart/form-data"  method="post">
                <div className="form-group ">
                   
                    <label htmlFor="name">Sous Catégorie</label>
                    <select className={`${errors.SubcategoryUuid? `form-control is-invalid`: `form-control` }`} value={product.SubcategoryUuid} onChange={onChange}  name="SubcategoryUuid" ref={register}  >
                        {
                            subCat.map(item=>(
                                <option key={item.uuid} value={item.uuid}>{item.subcatName}</option>
                            ))
                        }
                         
                    </select>
                    <div className="error ">{errors.SubcategoryUuid?.message}</div>
                </div>
                <div className="form-group">
                    
                    <label htmlFor="name">Nom de Produit</label>
                     <input type="text"  className={`${errors.productName? `form-control is-invalid`: `form-control` }`} id="name" name="productName" aria-describedby="emailHelp"   ref={register}   />
                     <div className="error ">{errors.productName?.message}</div>
                      
                  </div>
                  <div className="form-group">
                    
                         <label htmlFor="name">Intro</label>
                          <input type="text"  className={`${errors.productIntro? `form-control is-invalid`: `form-control` }`} id="name" name="productIntro" aria-describedby="emailHelp"   ref={register}  />
                          <div className="error ">{errors.productIntro?.message}</div>
                </div>  
                <div className="form-group">
                    
                    <label htmlFor="name">Description</label>
                     <textarea  className={`${errors.productDescription? `form-control is-invalid`: `form-control` }`} id="name" name="productDescription" aria-describedby="emailHelp" rows="6"   ref={register}  />
                     <div className="error ">{errors.productDescription?.message}</div>
           </div>
          
           <div className="form-group">
                    
                    <label htmlFor="name">Visible en Page d'accueil </label>
                     <select  className={`${errors.inHomePage? `form-control is-invalid`: `form-control` }`} id="name" name="inHomePage" aria-describedby="emailHelp"  ref={register}   >
                       <option value={true}>Visible</option>
                       <option value={false}>Caché</option>
                       </select>
                     <div className="error ">{errors.inHomePage?.message}</div>
           </div> 
                <div className="form-group">
                    
                    <label htmlFor="name">Caractéristiques </label>
                     <textarea  className={`${errors.characteristics? `form-control is-invalid`: `form-control` }`} id="name" name="characteristics" aria-describedby="emailHelp" rows="6"  ref={register}   />
                     <div className="error ">{errors.characteristics?.message}</div>
           </div> 
           <div className="form-group">
                    
                    <label htmlFor="name">Ordre</label>
                     <input type="text" className={`${errors.ordre? `form-control is-invalid`: `form-control` }`} id="name" name="ordre" aria-describedby="emailHelp"   ref={register}  />
                     <div className="error ">{errors.ordre?.message}</div>
                  </div>
           <div className='custom-file mb-4 col-md-6 col-lg-6'>
           <label>Image</label>
                        <input
                             type='file'
                             className={`${errors.img? `form-control is-invalid`: `form-control` }`}
                             id='customFile'
                             name="img"
                             ref={register}
                              />
                              <div className="error ">{errors.img?.message}</div>
                               </div>
        <div className='custom-file  mt-3 mb-5 col-md-6 col-lg-6  text-right' >
        <div className="mt-3">
               <img src={`../../imgs/${preloadedValues.productimgPath}`} style={{width:'40%',height:"40%"}} alt=""   />
           </div>
         
         
        </div>
             <div className='custom-file mb-4 col-md-6 col-lg-6' > 
         <label >Fiche Technique</label>
                   <input
                     type='file'
                     className='form-control'
                     id='customFile'
                     accept=".pdf"
                      name="ficheT"
                      ref={register}
                     />
                 
                       </div>
                       <div className="custom-file  mt-5 mb-5 col-md-6 col-lg-6  text-right">
                           
                         <div className="mt-3">
                         <h6>Fiche Technique</h6>
                         <a href={`../../files/${preloadedValues.ficheTechnique}` } target="blank"><FontAwesomeIcon icon={faFilePdf}  style={{width:'10%',height:"10%",color:"#FF2B3A"}} /></a>
                      
                         </div>
                       </div>
        <div className='custom-file mb-4 col-md-6 col-lg-6' >
            <label >Brochure </label>
                   <input
                     type='file'
                     className='form-control'
                     id='customFile'
                     accept=".pdf"
                      name="broch"
                      ref={register} 
                     />
                   
                       </div>
                       <div className="custom-file  mt-3 mb-5 col-md-6 col-lg-6  text-right">
                           
                       <div className="mt-3">
                         <h6>Brochure</h6>
                         <a href={`../../files/${preloadedValues.brochure}` } target="blank"><FontAwesomeIcon icon={faFilePdf}  style={{width:'10%',height:"10%",color:"#FF2B3A"}}  /></a>
                         </div>
                       </div>
                       <button type="submit" className="btn  my-3" style={{backgroundColor:"#FF2B3A",color:'#fff'}} >Modifier</button>
           
                </form>
                </div>
    )
}

import React,{useState,useEffect} from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faTrashAlt,faEdit} from "@fortawesome/free-solid-svg-icons";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Axios from "axios";

function GestSubcat() {
    const [subCat, setSubCat]=useState([]);
    const [modal, setModal] = useState(false);
    const [uuid, setUuid]= useState("")

  const toggle = (uuid) => {
   setModal(!modal);
   setUuid(uuid);  }
   const closeModal=()=>{
     setModal(!modal);
   }
    useEffect(() => {
        Axios.get("/sub-category/get-all")
             .then(res=>setSubCat(res.data))
             .catch(err=>console.log(err.message))
         
    }, [])
    const deletesubCat=async (uuid,e)=>{
        await  Axios.delete(`/sub-category/delete/${uuid}`);
         setSubCat(prevsubCat=>prevsubCat.filter(val=> val.uuid !== uuid));
         setModal(false);
        }
    return (
        <div className="container mt-5">
             <div className="col-lg-12 col-md-12 d-flex justify-content-between" style={{backgroundColor:'#c9c9c9'}}>
              <h1 className="justify-content-start" style={{color:'#000'}}>Ajouter Sous Catégorie</h1>
               <Link to="/gest-Sub-Category/add-sub-category" style={{ marginTop:"1%"}}><button className="btn  justify-content-end" style={{backgroundColor:"#FF2B3A",color:"#fff"}}>Ajouter </button></Link>
              </div>
              <div className="col-lg-12 col-md-12 ">
              <table className="table table-hover">
  <thead>
    <tr>
      <th scope="col">Nom de Sous Catégorie</th>
      <th scope="col">Image</th>
      <th scope="col">Catégorie</th>
      <th scope="col">Actions</th>
       
    </tr>
  </thead>
  <tbody>
  {
 subCat.map(subCat=>( 
   <tr key={subCat.uuid}>
  <th scope="row">
  {subCat.subcatName}
 </th>
  
  <td style={{width:"30%"}}><img src={"../../imgs/"+subCat.subcatimgPath} style={{width:"55px", height:"50px"}} alt="category img"/></td>
  <th scope="row" style={{width:"10%"}} >{subCat.Category.catName}</th> 
  <td><button className="btn btn-danger" onClick={(e)=>toggle(subCat.uuid)}>Supprimer <FontAwesomeIcon icon={faTrashAlt} style={{color:"#FFFFFF"}}/> </button> <Link to={`/gest-Sub-Category/edit-sub-category/${subCat.uuid}`} style={{color:"inherit"}}>  <button className="btn btn-info" >Modifier <FontAwesomeIcon icon={faEdit} style={{color:"#fff"}}/> </button></Link> </td> 
   </tr>
  ))
}
<Modal isOpen={modal} uuid={uuid} toggle={(e)=>closeModal}>
        <ModalHeader toggle={closeModal}>Vous êtes sûr ? </ModalHeader>
        <ModalBody>
        
La Sous Catégorie Sera Supprimée Définitivement
        </ModalBody>
        <ModalFooter>
          <Button color="btn btn-danger" onClick={(e)=>deletesubCat(uuid)}>Supprimer <FontAwesomeIcon icon={faTrashAlt} style={{color:"#FFFFFF"}}/> </Button>
          <Button color="secondary" onClick={(e)=>closeModal()}>Annuler</Button>
        </ModalFooter>
      </Modal>
     
     </tbody>
</table>
              </div>
        </div>
    )
}

export default GestSubcat

import '../App.css'; 

import React,{useState, useEffect} from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Axios from "axios";
import {Link} from "react-router-dom";
 
function CarouselHomePage() {
  const [carousel, setCarousel]=useState([]);
  useEffect(() => {
    Axios.get("/carousel/get-all")
         .then(response =>setCarousel(response.data))
         .catch(error => console.log(error.message))
  }, [])
    return (
   
       
          
        <Carousel   >  
          
               {carousel.map((val,key)=>( 
               <Carousel.Item id="carousel-item"  key={key}>  
              <img   
                  src={`../../imgs/${val.imgPath}`} className="image-homepage" alt="carousel"  /> 
               {val.title ? <div className="text-container">
  <div className="box bg-box"></div>
  <div className="box overlay">
    <h3 className="carousel-title">{val.title}</h3>
    <h3 className="carousel-subtitle">{val.subTitle}</h3>
  </div>
</div> :<></>}
                  
                      <Carousel.Caption>  
                     <Link to={val.link} style={{color : "inherit"}}>
                       <button >Voir Plus   </button></Link>
                     </Carousel.Caption> 
                        </Carousel.Item> ))} 
                       
        </Carousel>  
                 
         
    )
}

export default CarouselHomePage

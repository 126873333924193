import React,{useState} from 'react';
import Axios from 'axios';
import Message from '../Message';
import Progress from "../Progress";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

const schema = yup.object().shape({
  img: yup
    .mixed()
    .required("ce champ est obligatoire")
    .test("type", "ce champ doit être image de type jpeg or png", (value) => {
      return (value && value[0].type === "image/jpeg") || (value && value[0].type === "image/png");
    }),
  banner: yup
  .mixed()
  .required("ce champ est obligatoire")
  .test("type", "ce champ doit être image de type jpeg or png", (value) => {
    return (value && value[0].type === "image/jpeg") || (value && value[0].type === "image/png");
  }),
  name:yup
  .string()
  .required("ce champ est obligatoire"),
  intro:yup
  .string()
  .max(200, "ce champ doit comporter au maximum 200 caractères")
  .required("ce champ est obligatoire")
});
function AddCategory() {
     const history = useHistory();
     const [message, setMessage] = useState('');
     const [uploadPercentage, setUploadPercentage] = useState(0);
  
  const { register , handleSubmit, errors } = useForm({
    resolver: yupResolver(schema)
  }) 

  const onSubmit = async (data,e)=>{
    e.preventDefault();
    const newCat = new FormData()
    newCat.append('img', data.img[0])
    newCat.append('name',data.name)
    newCat.append('intro',data.intro)
    newCat.append("banner",data.banner[0])
    try {
      await Axios.post('/category/add-category', newCat, {
       headers: {
       'Content-Type': 'multipart/form-data'
       },
       onUploadProgress: progressEvent => {
      setUploadPercentage(
      parseInt(
       Math.round((progressEvent.loaded * 100) / progressEvent.total)
      )
      );
      //Clear form
      setTimeout(() => setUploadPercentage(0), 1000);
      setTimeout(() => setMessage(''), 1000);
      setTimeout(() => history.push("/gest-cat"),1100)
      }
      }).then(res=>{
       console.log(res)
      }).catch(err=>{
      console.log(err.message)
       });
      setMessage('File Uploaded');
      } catch (err) {
      if (err.response.status === 500) {
       setMessage('There was a problem with the server');
       } else {
      setMessage(err.response.data.msg);
      }
      }
 
}
    
  
         
    return (
        <div className="container mt-5">
              
                 <div className="col-md-8 col-lg-8">
                     <h1>Ajouter Catégorie </h1><br/>
                     {message ? <Message msg={message} /> : null}
                 <form onSubmit={handleSubmit(onSubmit)}  encType="multipart/form-data"  method="post">
                     <div className="form-group">
                    
                         <label htmlFor="name">Nom</label>
                          <input type="text" className={`${errors.name? `form-control is-invalid`: `form-control` }`} id="name" name="name" aria-describedby="emailHelp" ref={register}  />
                          <div className="error ">{errors.name?.message}</div>
                       </div>
                       <div className="form-group">
                    
                         <label htmlFor="name">Intro</label>
                          <textarea type="text" className={`${errors.intro? `form-control is-invalid`: `form-control` }`} id="name" name="intro" aria-describedby="emailHelp" ref={register}  />
                          <div className="error ">{errors.intro?.message}</div>
                       </div>
                       
                       <div className='form-group mb-4'>
                      <label>Image</label>
           <input
            type='file'
            className={`${errors.img? `form-control is-invalid`: `form-control` }`}
            id='customFile'
            ref={register}
            name="img"
          />
           <div className="error ">{errors.img?.message}</div>
          </div>
         <label>Image De Couverture</label>
        <div className='form-group mb-4'>
           <input
            type='file'
            className={`${errors.banner? `form-control is-invalid`: `form-control` }`}
            id='customFile'
            name="banner"
            ref={register}
          />
          <div className="error ">{errors.banner?.message}</div>
          </div>
          
        
         <Progress percentage={uploadPercentage} />
                    
                    <br/>
                    <button type="submit" className="btn" style={{backgroundColor:"#FF2B3A",color:'#fff'}}  >Ajouter</button>
                </form>
                </div>
                </div>
               
    )
}

export default AddCategory


import React, { useEffect,useReducer} from 'react';
import styled from 'styled-components';
import {faCog, faPhoneAlt, faLaptop } from "@fortawesome/free-solid-svg-icons";
import {NavLink} from 'react-router-dom';
import { HashLink   } from 'react-router-hash-link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import Loading from "../Loading";
import $ from 'jquery';
import {ReactComponent as CARET } from "../icons/caret.svg";
import {
    NavItem,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu }
     from 'reactstrap';
import Search from '../Search';

 const Ul = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: row nowrap; 
  li {
    padding: 50px 10px;
  }
  @media (max-width: 768px) {
    margin-top: 100px;
    flex-flow: column nowrap;
    background-color: #fff;
    position: fixed;
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
    top: 0;
    right: 0; 
    height: 100%;  
    width: 70%;
     
    transition: transform 0.3s ease-in-out;
    
    li  {
      color: #fff;
      padding:  10px 10px;
       
       
    }
    
  }
`;
const initialState={
    loading:true,
    error:'',
    post:{}
  }
const reducer=(state,action)=>{
    switch(action.type){
        case "fetch success":
            return {
                loading:false,
                categories: action.payload,
                error:''
            }
       case "fetch error":
           return {
               loading:false,
               categories: {},
               error: "something went wrong "
           }
        default :
          return state
    }
    }

const RightNav = ({ open ,callBack,navRef }) => { 
    const [state,dispatch]= useReducer(reducer , initialState)
    function blurrBody(){
      if(!navRef.current.classList.contains("wrapper-navbar") && !open){
        document.body.classList.add("blur-body")
         
       }else{
        navRef.current.classList.remove("wrapper-navbar")
       document.body.classList.remove("blur-body")
         
       }
      
    }
    useEffect(()=>{
        Axios.get(`/category/get-all`)
             .then(res=>{
                 dispatch({type:"fetch success", payload:res.data})
                 })
             .catch(error=>{
                  dispatch({type:"fetch error"})
             })
    },[])
    useEffect(() => {
        $('body').on('mouseenter mouseleave ','.nav-item',function(e){
                if ($(window).width() > 767) {
                    var _d=$(e.target).closest('.nav-item');
                    _d.addClass('show');
                    setTimeout(function(){
                    _d[_d.is(':hover')?'addClass':'removeClass']('show');
                    },1);
                }
      });	
    }, [])
    useEffect(() => {
      window.addEventListener("click", handleClickOutside);
      return () => {
        window.removeEventListener("click", handleClickOutside);
      };
    },[]);
    const handleClickOutside = event => {
      const { current: wrap } = navRef;
      if (wrap && !wrap.contains(event.target)) {
        callBack(open);
        navRef.current.classList.remove("wrapper-navbar")
       document.body.classList.remove("blur-body")
      }
    };
    
    
  return (
    
    
    <Ul open={open} className={`${open ? 'nav-mobile' : ''}`} >
       <NavItem className={`${open ? "d-flex  nav-link-phone":""}`}  onClick={()=>open ? (callBack(!open),blurrBody()):null} >
                
                <NavLink exact    to={{pathname:"/"}} className="nav-link text-uppercase" activeClassName="nav-active nav-link text-uppercase"    >Accueil</NavLink >
             
         </NavItem>
         <UncontrolledDropdown nav inNavbar   className={`${open ? "nav-link-phone":""}`} >
         <DropdownToggle   nav className={`${open ? "d-flex":""}`} >
         
           <NavLink to="/entreprise" className="nav-link text-uppercase" activeClassName="nav-active nav-link text-uppercase" onClick={()=>open ? callBack(!open):null}> Découvrez-nous</NavLink>
           {open ? <CARET      className="icon-navbar ml-auto active dropdown-toggle"   /> : <></>}
         </DropdownToggle>
         
         <DropdownMenu className="dropdown-menu" >
         <HashLink smooth className="dropdown-item" style={{textDecoration:"none"}} to={{pathname:"/entreprise",hash:"gst" }} onClick={()=>open ? callBack(!open):null}>
           
            GST
            
           </HashLink> 
           <HashLink smooth className="dropdown-item" style={{textDecoration:"none"}} to={{pathname:"/entreprise",hash:"partenaires" }} onClick={()=>open ? callBack(!open):null}>
            
            Partenaires
            
           </HashLink>
           <HashLink smooth className="dropdown-item" style={{textDecoration:"none"}} to={{pathname:"/entreprise",hash:"equipe" }} onClick={()=>open ? callBack(!open):null}> 
         
           Equipe
           
           </HashLink>
           <HashLink smooth className="dropdown-item" style={{textDecoration:"none"}} to={{pathname:"/entreprise",hash:"ref" }} onClick={()=>open ? callBack(!open):null}> 
           
            Nos Références
       
           </HashLink>
         </DropdownMenu>
       </UncontrolledDropdown>
         <UncontrolledDropdown nav inNavbar className={`${open ? "nav-link-phone":""}`} >
         <DropdownToggle nav className={`${open ? "d-flex":""}`} >
          
           <NavLink to={{pathname:"/produit"}} className="nav-link text-uppercase" activeClassName="nav-active nav-link text-uppercase" onClick={()=>open ? callBack(!open):null}> Produits</NavLink> 
           {open ? <CARET       className="icon-navbar ml-auto active"    />:<></>}  
         </DropdownToggle>
         <DropdownMenu className="dropdown-menu" >
          
           {state.loading ? <Loading/>: state.categories.map(val=>(
           
           <a href={`/${val.catName}/${val.uuid}`}  key={val.uuid} style={{textDecoration:"none"}} className="dropdown-item" onClick={()=>open ? callBack(!open):null} >
             {val.catName}
             </a>
              ))}
           {state.error ? state.error : null}
         </DropdownMenu>
       </UncontrolledDropdown>
         <UncontrolledDropdown nav inNavbar  className={`${open ? "nav-link-phone":""}`} >
         <DropdownToggle nav  className={`${open ? "d-flex":""}`} >
          
           <NavLink to={{pathname:"/service"}} className="nav-link text-uppercase" activeClassName="nav-active nav-link text-uppercase" onClick={()=>open ? callBack(!open):null}> Services</NavLink>
           {open ? <CARET      className="icon-navbar ml-auto active" /> : <></>}
         </DropdownToggle>
         <DropdownMenu className="dropdown-menu" >
           
           <HashLink onClick={()=>open ? callBack(!open):null} smooth className="dropdown-item" style={{textDecoration:"none"}} to={{pathname:"/service",hash:"maintenance" }}>
             <FontAwesomeIcon style={{color:"#FF2B3A"}} className="mr-2" icon={faCog} />
             Maintenance
             </HashLink>
           
            
           <HashLink onClick={()=>open ? callBack(!open):null} smooth className="dropdown-item" style={{textDecoration:"none"}} to={{pathname:"/service",hash:"supportTechnique" }}>
           <FontAwesomeIcon style={{color:"#FF2B3A"}} className="mr-2" icon={faPhoneAlt} /> Support Technique
           </HashLink>
             
           <HashLink onClick={()=>open ? callBack(!open):null} smooth className="dropdown-item" style={{textDecoration:"none"}} to={{pathname:"/service",hash:"formation" }}>
           <FontAwesomeIcon style={{color:"#FF2B3A"}} className="mr-2" icon={faLaptop} /> Formation
           </HashLink>
            
           
         </DropdownMenu>
       </UncontrolledDropdown>
          
         <NavItem  className={`${open ? "d-flex nav-link-phone":"mr-3"}`} onClick={()=>open ? callBack(!open):null} >
               
         <NavLink to={{pathname:"/contact"}} className="nav-link text-uppercase" activeClassName="nav-active nav-link text-uppercase" >Contact</NavLink>
                 
         </NavItem>
      <div className={`${open ? "nav-link-phone ":""}`}> <Search open={open}/> </div>
    </Ul>
     
  
    
    
  )
}

export default RightNav
import React from 'react'; 
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
function Search({open}) {
  const { register, handleSubmit, reset } = useForm({});
  const onSubmit = async (data, e) => {
    e.preventDefault();
    history.push(`/search/${data.word}`);
    reset();
   
  };
  const history = useHistory();
    return (
        <div className={open ? "mt-0" : "mt-5"}>
        <form onSubmit={handleSubmit(onSubmit)}>
       <div className="input-group">
       
      <input 
      type="text" 
      className="form-control search"  
      name="search" 
      placeholder=" chercher..."  
      ref={register}
      name="word"  />
      <div className="input-group-btn ">
        <button className="btn btn-light " type="submit">
        <FontAwesomeIcon icon={faSearch} style={{ color: "#FF2B3A" }} />
          </button>
           </div>
           </div>
    </form>
      </div>
    )
}

export default Search

import React,{useState,useEffect} from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Axios from 'axios';
import { useHistory } from "react-router-dom";
const schema = yup.object().shape({
    CategoryUuid:yup
    .string()
    .required("ce champ est obligatoire"),
    subcatName:yup
    .string()
    .max(50, "ce champ doit comporter au maximum 50 caractères")
    .required("ce champ est obligatoire"),
    introsubCat:yup
    .string()
    .max(200, "ce champ doit comporter au maximum 200 caractères")
    .required("ce champ est obligatoire"), 
    ordre:yup
    .number("ce champ doit etre numerique")
    .required("ce champ est obligatoire"),
  });
export default function FormsubCat({preloadedValues, match}) {
    
    const history = useHistory();
    const { register, handleSubmit, errors } = useForm({
        defaultValues: preloadedValues,
        resolver: yupResolver(schema)
      });
      
      const onSubmit =   (data,e) => {
        e.preventDefault();
        const updatedCat = new FormData()
        updatedCat.append('subcatimgPath', data.img[0])
        updatedCat.append("subcatbannerimgPath",data.banner[0])
        updatedCat.append("subcatName",data.subcatName)
        updatedCat.append("introsubCat",data.introsubCat)
        updatedCat.append("CategoryUuid",data.CategoryUuid) 
        updatedCat.append('ordre',data.ordre) 
           Axios.put(`/sub-category/edit/${match}`,updatedCat)
        .then((res)=>{
                console.log(res.data);
                history.push("/gest-Sub-Category");
            })
        .catch(err=>console.log(err.message))
        };
        const [subCat,setSubCat]=useState([])
   useEffect(()=>{
       Axios.get( "/category/get-all")
            .then(res=>{
                setSubCat(res.data)
                })
            .catch(error=>{
                 console.log(error.message)
            })
   },[])
   const [cat, setCat]= useState({
    CategoryUuid : ""
   })
    useEffect(() => {
        Axios.get(`/sub-category/get-one/${match}`)
                    .then((result)=>setCat(result.data[0]))
                    .catch((error)=>console.log(error.message));
                }, [match])
                const onChange = (e) => {
                    setCat({...cat, [e.target.name]: e.target.value});
                  }
    
    return (
        <div className="container mt-5">
             <div className="row row-a">
                 <div className="col-md-8 col-lg-8">
                     <h1>Modifier Catégorie </h1><br/>
                  <form   onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data"  >
                  <div className="form-group">
                         <label htmlFor="name">nom de catégorie</label>
                          <select className={`${errors.CategoryUuid? `form-control is-invalid`: `form-control` }`} id="name" value={cat.CategoryUuid} onChange={onChange} name="CategoryUuid" ref={register}>
                          {
                            subCat.map(item => (
                                <option key={item.uuid} value={item.uuid}   >{item.catName}</option>
                            ))
                          }
                         </select>
                          <div className="error ">{errors.CategoryUuid?.message}</div>
                       </div>
                     <div className="form-group">
                         <label htmlFor="name">nom</label>
                          <input type="text" className={`${errors.subcatName? `form-control is-invalid`: `form-control` }`} id="name" name="subcatName" ref={register}  />
                          <div className="error ">{errors.subcatName?.message}</div>
                       </div>
                       <div className="form-group">
                         <label htmlFor="name">intro</label>
                          <textarea className={`${errors.introsubCat? `form-control is-invalid`: `form-control` }`} id="name" name="introsubCat"  ref={register}  />
                          <div className="error ">{errors.introsubCat?.message}</div>
                       </div>
                       <div className="form-group">
                    
                    <label htmlFor="name">Ordre</label>
                     <input type="text" className={`${errors.ordre? `form-control is-invalid`: `form-control` }`} id="name" name="ordre" aria-describedby="emailHelp"   ref={register}  />
                     <div className="error ">{errors.ordre?.message}</div>
                  </div>
                       
                        <div className='custom-file mb-4 col-md-6 col-lg-6'>
                        <label>Image De Couverture</label>
                        <input
                             type='file'
                             className={`${errors.img? `form-control is-invalid`: `form-control` }`}
                             id='customFile'
                             name="img"
                             ref={register}
                              />
                              <div className="error ">{errors.img?.message}</div>
                               </div>
        <div className='custom-file  mt-3 mb-5 col-md-6 col-lg-6  text-right' >
           <div>
               <img src={`../../imgs/${preloadedValues.subcatimgPath}`} style={{width:'40%',height:"40%"}} alt=""   />
           </div>
         
         
        </div>
        
                      
        <div className='custom-file mb-4 mt-4 col-md-6 col-lg-6'>
        <label>Image De Couverture</label>
           <input
            type='file'
            className={`${errors.banner? `form-control is-invalid`: `form-control` }`}
            id='customFile'
            name="banner"
            ref={register}/>
            <div className="error ">{errors.banner?.message}</div>
             </div>
        <div className='custom-file  mt-3 mb-1 col-md-6 col-lg-6  text-right' >
           <div>
               <img src={`../../imgs/${preloadedValues.subcatbannerimgPath}`} style={{width:'40%',height:"40%"}} alt=""   />
           </div>
         
         
        </div>
        <button type="submit" className="btn  mt-5" style={{backgroundColor:"#FF2B3A",color:'#fff'}} >Modifier</button>
                </form>
                </div>
                </div>
                </div>
    )
}

import React ,{useState,useEffect} from 'react';
import Axios from 'axios';
import {ReactComponent as SC} from "./icons/Serv comm-01.svg";
import {ReactComponent as ST} from "./icons/serv technique-01.svg"
import {ReactComponent as MR} from "./icons/reparation-01.svg"
import { HashLink as Link } from 'react-router-hash-link';

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
function EntreprisePage() {
  const[clients, setClients]=useState([])
  useEffect(() => {
      Axios.get("/client/get-all")
           .then(res=>setClients(res.data))
           .catch(err=>console.log(err.message))
  }, [])
  const[teams, setTeams]=useState([])
    useEffect(() => {
        Axios.get("/team/get-all")
             .then(res=>setTeams(res.data))
             .catch(err=>console.log(err.message))
    }, [])
     return (
       <>
       <section id="toppage">
	 <nav>
		<ol className="cd-breadcrumb custom-separator">
			<li><Link className="breadcrumb-link" to="/">Accueil</Link></li>
			 <li className="current"><em>Découvrez-nous</em></li>
		</ol>
	</nav>
</section>
        <div className="box-pe"  >
           <div   className="body-slogan">
            <div className="head-slogan  font-weight-bold">
              Geosystems Tunisie  </div> 
<div className="slogan"> 
  <span className="text-slogan">  Partenaire des Géomètres</span>
</div>
        </div>
       <div className="container-fluid">
           <div className="row mx-md-5 mx-lg-5 mt-5"> 
          <div className="col-lg-12 col-md-12 col-12" id="gst">
          <h3 className="font text-uppercase h4  mb-1 ">
            <span className="before-title   mr-3 "></span>Geosystems Tunisie
              </h3>
          <p className=" text-left gst-intro ">
          Fondée en 1998, GEOSYSTEMS TUNISIE se spécialise depuis sa création dans la distribution d’instruments reliés à la TOPOGRAPHIE et à la CONSTRUCTION.
GST offre une large gamme de services techniques ou services après-vente, réalisés par des techniciens hautement qualifiés utilisant des outils professionnels du LEICA GEOSYSTEMS. 
Différents niveaux de maintenance sont proposés à la carte, en fonction de chaque instrument et de leur utilisation.
GST bénéficie de la confiance des professionnels dans des secteurs aussi variés que l'aérospatiale, la défense, la protection, la sécurité, la construction, la production et toutes leurs exigences géospatiales.
Grâce à des instruments précis, des logiciels élaborés et à des services fiables, Geosystems Tunisie offre chaque jour des outils et des services de qualité à ses clients.

          </p>
          <h4>
          Geosystems Tunisie Répresentant exclusif de Leica Geosystems qui fait partie du groupe Hexagon, fournisseur mondial, leader des technologies de l’information qui permettent d’optimiser la productivité et la qualité pour les applications géospatiales et industrielles
          </h4>
          </div>
          
           
           <div className="col-lg-12 col-md-12 col-12 my-4 " id="partenaires">
          <h3 className="font text-uppercase h4 my-1 "><span className="before-title  mr-3 "></span>Partenaire</h3>
          <div className="text-center image-entreprise-gst">
            <img src="../../imgs/leica-geo.jfif" className="img-fluid "  alt="leica geosysytems" />
         </div>
          </div>

          <div className="col-lg-12 col-md-12 col-12 my-3" id="equipe">
          <h3 className="font text-uppercase my-3  h4"><span className="before-title   mr-3 "></span>équipe</h3>
         <div className="row">
         {teams.map((item,key)=>(<div className="col-md-4 col-lg-4 col-12 my-2" key={key}>
              <div className="team py-5">
              {item.poste ==="Maintenance & Réparation" ? <MR/> : item.poste ==="Service Commercial" ? <SC/> : <ST/>}
               <h3>{item.poste}</h3>
               <h3 className="emails-team ">{item.email}</h3> 
              </div>
          </div>))}
         
         </div>
          </div> 
           <div className="col-md-12 col-lg-12 col-12 mt-5" id="ref"> 
           <h3 className="font text-uppercase my-3  h4"><span className="before-title  mr-3 "></span>Nos Références</h3>
        <Carousel
          additionalTransfrom={1}
          arrows={false} 
          centerMode={false}
         infinite
          draggable
          focusOnSelect={false}
          autoPlay
           autoPlaySpeed={2000}
          keyBoardControl
          minimumTouchDrag={80}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          responsive={{
            desktop: {
              breakpoint: {
                max: 3000,
                min: 1024
              },
              items: 5,
               
            },
            mobile: {
              breakpoint: {
                max: 464,
                min: 180
              },
              items: 3,
              
            },
            tablet: {
              breakpoint: {
                max: 1024,
                min: 464
              },
              items: 5,
               
            }
          }}
          showDots={false}
          sliderClass="my-3" 
          swipeable
          slidesToSlide={3}
      >
            {clients.map((item,key)=>( 
            <div className="owl-item" key={key}>
            <div className="bbb_viewed_item discount d-flex flex-column align-items-center justify-content-center text-center">
                <div className="bbb_viewed_image">
                  
                    <img src={`../../imgs/${item.imgPath}`} alt="produits" />
                     
                    </div>
               
                 
            </div>
        </div> 
            ))}
          </Carousel>
           
           </div>
       
          
       </div>  
       </div> 
        
       
  </div>
  </>

    )
}

export default EntreprisePage

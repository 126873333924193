import React,{useState} from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {NavLink} from 'react-router-dom';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
   
} from 'reactstrap';
const NavigationAuth=()=>{
    const { logout } = useAuth0();
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
    return (
        <Navbar color="light" dark expand="md" className=" sticky-top">
        
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav navbar>
          <NavItem>
               <NavLink to="/gest-cat"   className="admin-links ml-2 mr-2" style={{textDecoration:"none"}} activeClassName="admin-links nav-active"   >Gestion Catégorie</NavLink>
            </NavItem>
          
           
            <NavItem>
              <NavLink to="/gest-Sub-Category" className="admin-links ml-2 mr-2" style={{textDecoration:"none"}} activeClassName="admin-links nav-active"   > Gestion Sous Catégorie</NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/gest-Produit" className="admin-links ml-2 mr-2" style={{textDecoration:"none"}}  activeClassName="admin-links nav-active"   >Gestion Produit</NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/gest-images" className="admin-links ml-2 mr-2" style={{textDecoration:"none"}} activeClassName="admin-links nav-active"   >Gestion Images  Produit </NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/gest-caroussel" className="admin-links ml-2 mr-2" style={{textDecoration:"none"}} activeClassName="admin-links nav-active"   > Gestion Carousel </NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/gest-banner" className="admin-links ml-2 mr-2"  style={{textDecoration:"none"}} activeClassName="admin-links nav-active"  > Gestion Banners</NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/gest-ref" className="admin-links ml-2 mr-2"  style={{textDecoration:"none"}} activeClassName="admin-links nav-active"  > Gestion Clients</NavLink>
            </NavItem>
            <NavItem>
               <NavLink to="/gest-home"   className="admin-links ml-2 mr-2" style={{textDecoration:"none"}} activeClassName="admin-links nav-active"   >Gestion Accueil</NavLink>
            </NavItem>
            <NavItem>
               <NavLink to="/gest-equipe"   className="admin-links ml-2 mr-2" style={{textDecoration:"none"}} activeClassName="admin-links nav-active"   >Gestion Equipe</NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/logout" className="admin-links ml-2 mr-2" style={{textDecoration:"none"}} activeClassName="admin-links nav-active"  onClick={() => logout()} >   Log out </NavLink>
            </NavItem>
            
             
          </Nav>
          
        </Collapse>
      </Navbar>
     
    )
}

export default NavigationAuth
